import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorService } from '../../behavior.service';
@Component({
  selector: 'app-addclient',
  templateUrl: './addclient.component.html',
  styleUrls: ['./addclient.component.css']
})
export class AddclientComponent implements OnInit {
  clientForm: FormGroup;
  _host = environment.url
  clientEditData: any
  addSubmitted: boolean = false;
  countries: any;
  submitLoader: boolean = false



  socialMediaLink: FormArray;
  contactPersonArray: FormArray;
  ContactPersonForm: FormGroup;
  extraDetails: FormArray;
  contactSubmitted: boolean;
  contactListValueArray: any = [];
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _bs:BehaviorService

  ) {
    this.createForm();
    this.addClientFunc();
    this.commonService.clientAdded.subscribe(res => {
      if (res == false) {
        this.clientForm.reset();
        this.addSubmitted = false
      }
    })
    this.commonService.editClientData.subscribe(res => {
      if (res != '') {
        // console.log('res',res)
        this.clientEditData = res
        this.clientForm.patchValue({
          clientType: res['clientType'],
          companyName: res['companyName'],
          firstName: res['firstName'],
          lastName: res['lastName'],
          address1: res['address1'],
          address2: res['address2'],
          email: res['email'],
          zipCode: res['zipCode'],
          mobileNo: res['mobileNo'],
          country: res['country']
        })
        this.clientForm.controls.email.disable()
      } else {
        this.clientEditData = ''
        this.clientForm.controls.email.enable()

      }

    })
  }

  ngOnInit() {
    this.contactPersonCreateForm()
    this.getCountries();

  }
  addClientFunc() {

  }
  createForm() {
    this.clientForm = this.formBuilder.group({
      clientType: ['', [Validators.required]],
      companyName: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      address1: ['',],
      address2: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{1,4}.$")]],
      zipCode: ['', [, Validators.minLength(4), Validators.maxLength(8), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      mobileNo: ['', [Validators.minLength(8)]],
      country: [''],
      countryCode:['',]
    })
  }

  get cf() { return this.clientForm.controls }
  Submit() {
    this.addSubmitted = true;
    // console.log(this.clientForm.controls,"this.clientForm.controls")
    if (!this.addSubmitted && this.clientForm.valid) {
      return
    }
    if (this.addSubmitted && !this.clientForm.invalid) {
      this.submitLoader = true
      let datas = this.clientForm.value
      datas = {
        clientType: this.clientForm.value.clientType,
        firstName: this.clientForm.value.firstName,
        lastName: this.clientForm.value.lastName,

        email: this.clientForm.value.email,

        mobileNo: this.clientForm.value.mobileNo,
        address: {
          address1: this.clientForm.value.address1,
          address2: this.clientForm.value.address2,
          country: this.clientForm.value.country,
          zipCode: this.clientForm.value.zipCode,
        }

      }
      if (this.clientForm.value.clientType == 'business') {
        datas['clientInfoDetails'] = {
          clientType: this.clientForm.value.clientType,
          companyName: this.clientForm.value.companyName
        }
      }

      if (this.clientEditData == '') {
        this.commonService.add('client', datas).subscribe(res => {

          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');
            this._bs.setDirestion('reload_client')

            this._bs.closeModals(false)
            this.clientForm.reset();
    this.addSubmitted = false;

            this.submitLoader = false;
            this.commonService.clientAdded.next(true)
          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.submitLoader = false
        },
          error => {
            this.submitLoader = false
            // this.toastr.error(error, 'Error');
          });
      } else {
        datas['email'] = this.clientEditData.email
        let params = new HttpParams().set('?id', this.clientEditData.id)
        this.commonService.updatePut('user', datas, params).subscribe(res => {

          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');

            this.clientForm.reset();
            this.submitLoader = false;
            this.commonService.clientAdded.next(true)
          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.submitLoader = false
        },
          error => {
            this.submitLoader = false
            // this.toastr.error(error, 'Error');
          });
      }


    }
  }


  getCountries() {
    this.commonService.get('countrys').subscribe(res => {
      if (res.success) {
        this.countries = res.data
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
    },
      error => {
        //  this.toastr.error(error, 'Error');
      }
    );
  }

  // add contact person
  contactPersonCreateForm() {
    this.ContactPersonForm = this.formBuilder.group({

      contactPersonArray: this.formBuilder.array([])
    });


  }
  addContactFunc() {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    if (this.contactPersonArray.length == 0) {
      this.contactSubmitted = false;
      this.contactPersonArray.push(this.createContact());
    } else if (this.contactPersonArray.length > 0) {
      this.contactSubmitted = true;

      if (this.contactSubmitted && !this.ContactPersonForm.invalid) {
        this.contactListValueArray.push(...this.ContactPersonForm.controls.contactPersonArray.value)
        this.contactSubmitted = false
        this.contactPersonArray.push(this.createContact());

      }
    }


  }
  removeContacFunc(index) {

    this.contactPersonArray.removeAt(index)
    // this.contactPersonArray.removeAt(this.contactPersonArray.value.findIndex(image => image.id === index) )
  }
  get cTf() { return this.ContactPersonForm.controls; }
  createContact():
    FormGroup {
    return this.formBuilder.group({
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{1,4}.$")]],
      mobileNo: ['', [Validators.required, Validators.minLength(8)]],
      // phonebusiness: ['', [Validators.minLength(10),Validators.maxLength(12)]], 
      note: [''],
      progressBar: ['false'],
      profileImage: [''],
      headline: [''],
      extraDetails: this.formBuilder.array([this.createDocument()]),
      socialMediaLink: this.formBuilder.array([this.createSocialLink()]),
      // companyId: [''],
      role: ['', [Validators.required]]
    })

  }
  contactPersonArrayFunc(): FormArray {
    return this.ContactPersonForm.get('contactPersonArray') as FormArray;
  }
  contactPersonDocList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('extraDetails') as FormArray;
  }

  addContactSocialLink(empIndex: number) {
    this.socialMediaLink = this.contactPersonArray['controls'][empIndex]['controls']['socialMediaLink'] as FormArray

    this.socialMediaLink.push(this.createSocialLink())
    // console.log('this.contactPersonDocList',this.contactPersonArray['controls'][0]['controls']['socialMediaLink'])
  }
  createSocialLink():
    FormGroup {
    return this.formBuilder.group({
      link: [''],
    })
  }
  addContactDocs1(empIndex: number) {
    this.extraDetails = this.contactPersonArray['controls'][empIndex]['controls']['extraDetails'] as FormArray

    this.extraDetails.push(this.createDocument())
    // console.log('this.contactPersonDocList',this.contactPersonArray['controls'][0]['controls']['extraDetails'])
  }

  createDocument():
    FormGroup {
    return this.formBuilder.group({
      progressBar: ['false'],
      docTitle: [''],
      docs: [[]],
    })

  }
  //  contact person end
}
