import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'
import { CommonService } from 'src/app/services/common.service';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';



@Component({
  selector: 'app-shared-add-client',
  templateUrl: './shared-add-client.component.html',
  styleUrls: ['./shared-add-client.component.css']
})
export class SharedAddClientComponent implements OnInit {
  @Input() commingData: any
  // options: Options  = {
  //   bounds: undefined,
  //   fields: ['address_component'],
  //   strictBounds: false,
  //   types: ['address'],
  //   origin: undefined,
  //   componentRestrictions: undefined

  // };
  @ViewChild('places', { static: false }) places: GooglePlaceDirective;

  @ViewChild('contactProfileInput', { static: false }) contactProfileInput;
  @ViewChild('uploadDocs', { static: false }) uploadDocs;
  progress: number = 0;
  _host = environment.url
  fileToUpload: File = null;
  countries: any;
  titleListData: any;
  userImage: any;
  roleAndTitleForm: FormGroup;
  roleTitleSubmitted: boolean = false
  public roles: Array<any> = [];
  actionType: any = 'active'
  contactSubmitted: boolean = false;
  contactListValueArray = []
  contactPersonArray: FormArray;
  ContactPersonForm: FormGroup;
  extraDetails: FormArray;
  socialMediaLink: FormArray;
  websiteArray: FormArray;
  addType: any = '';
  roleModal: boolean = false
  commonSubmitted: boolean = false;
  public autionData: any;
  states: any = [];
  cities: any = [];
  country: any = "";
  countryShortName: any;
  state: any = "";
  city: any = "";
  conditionalForm: boolean = false;
  editData: any;
  commonAddClientForm: FormGroup;
  userType: any = 'business';
  userID: any = '';
  pageNo: any;
  roleData: any
  clientType: any = '';

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _activateRouter: ActivatedRoute,
  ) {
    // this.userID = this._activateRouter.snapshot.params['id'];
    this.pageNo = this._activateRouter.snapshot.params['page'];


    this.createCommonForm()

    this.getCountries()
    this.getRoles();
    this.getTitleList();

    this.commonService.clientAdded.subscribe(res => {
      if (res == false) {
        this.commonAddClientForm.reset();
        this.commonAddClientForm.controls.countryCode.setValue('+1')
        this.commonSubmitted = false
      }
    })
    this.commonService.editClientData.subscribe(res => {
      if (res != '' && res != null && res != undefined) {
        this.userID = res
        // //console.log('res',res)
        this.conditionalForm = true
        this.getData()
        // this.clientEditData=res
        //         this.commonAddClientForm.patchValue({
        //           clientType:res['clientType'],
        //           companyName: res['companyName'],
        //           firstName: res['firstName'],
        //           lastName: res['lastName'],
        //           address1: res['address1'],
        //           address2: res['address2'],
        //           email: res['email'],
        //           zipCode:res['zipCode'],
        //           mobileNo:res['mobileNo'],
        //           country:res['country']
        //         })
        this.commonAddClientForm.controls.email.disable()
      } else {
        this.autionData = ''
        this.conditionalForm = false
        this.commonAddClientForm.controls.email.enable()

      }

    })
  }

  ngOnInit() {
    this.contactPersonCreateForm()

    if (this.userID) {
      this.conditionalForm = true

    }
    this.roleAndTitleForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    })

  }

  createCommonForm() {
    this.commonAddClientForm = this.formBuilder.group({

      clientType: ['',],
      companyName: [''],
      firstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      address1: ['',],
      address2: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{1,4}.$")]],
      zipCode: ['', []],
      countryCode: ['+1'],
      mobileNo: [''],
      country: [''],
      note:[''],
      state: [''],
      companyId: [''],
      lastMeeting: [''],
      nextMeeting: [''],
      city: [''],

      // websiteArray:this.formBuilder.array([this.createWebsite()]),
    })
  }

  onChange(address: Address) {
    // //console.log('address',address.formatted_address,address)
    if (address.photos && address.photos.length > 0) {
      console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
    }
    let formatted_address = address.formatted_address
    let address_components = address.address_components
    for (let index = 0; index < address_components.length; index++) {
      const element = address_components[index];
      if (address_components[index].types[0] == "country") {
        this.country = address_components[index].long_name
        this.getStates(this.country)
        this.commonAddClientForm.controls.country.setValue(address_components[index].long_name)
      } else if (address_components[index].types[0] == "administrative_area_level_1") {
        this.state = address_components[index].short_name
        this.commonAddClientForm.controls.state.setValue(address_components[index].short_name)
        setTimeout(() => {
          this.getCities()
        }, 500);
      } else if (address_components[index].types[0] == "locality" || address_components[index].types[0] == "administrative_area_level_3") {
        setTimeout(() => {
          this.commonAddClientForm.controls.city.setValue(address_components[index].long_name)
        }, 1000);

      } else if (address_components[index].types[0] == "postal_code") {
        this.commonAddClientForm.controls.zipCode.setValue(address_components[index].long_name)
      }
    }
  }

  getComponentByType(address: Address, type: string): AddressComponent {
    if (!type)
      return null;

    if (!address || !address.address_components || address.address_components.length == 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }
  get cMf() { return this.commonAddClientForm.controls; }
  get rTF() { return this.roleAndTitleForm.controls }
  contactPersonCreateForm() {
    this.ContactPersonForm = this.formBuilder.group({

      contactPersonArray: this.formBuilder.array([])
    });


  }

  getRoles() {
    let data = new HttpParams().set('?userType', this.userType)

    this.commonService.getWithParms('role/name', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.roleData = [];
      } else {
        this.roleData = [];
        this.roles = response.data;
        this.roles.forEach(element => {
          if (element.name != "" && element.name != null && element.name !== undefined) {

            this.roleData.push(element)
          }
        });
      }
    });
  }
  createContact():
    FormGroup {
    return this.formBuilder.group({
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{1,4}.$")]],
      contactcountryCode: ['+1'],
      contactmobileNo: ['', [Validators.required, Validators.minLength(8)]],
      // phonebusiness: ['', [Validators.minLength(10),Validators.maxLength(12)]], 
      note: [''],
      progressBar: ['false'],
      profileImage: [''],
      headline: [''],
      extraDetails: this.formBuilder.array([this.createDocument()]),
      socialMediaLink: this.formBuilder.array([this.createSocialLink()]),
      // companyId: [''],
      role: ['', [Validators.required]]
    })

  }
  createDocument():
    FormGroup {
    return this.formBuilder.group({
      progressBar: ['false'],
      docTitle: [''],
      docs: [[]],
      note: [''],
    })

  }
  createSocialLink():
    FormGroup {
    return this.formBuilder.group({
      link: [''],
    })
  }
  createWebsite():
    FormGroup {
    return this.formBuilder.group({
      website: [''],
    })
  }
  contactPersonArrayFunc(): FormArray {
    return this.ContactPersonForm.get('contactPersonArray') as FormArray;
  }
  // websiteArrayFunc(): FormArray {
  //   return this.commonAddClientForm.get('websiteArray') as FormArray;
  // }
  contactPersonDocList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('extraDetails') as FormArray;
  }
  contactPersonSocialList(empIndex: number): FormArray {
    return this.contactPersonArrayFunc()
      .at(empIndex)
      .get('socialMediaLink') as FormArray;
  }

  addWebsiteFunc() {
    this.websiteArray = this.commonAddClientForm.get('websiteArray') as FormArray;

    this.websiteArray.push(this.createWebsite());
  }

  removeWebsiteFunc(i: number) {
    this.websiteArray.removeAt(i);
  }

  addContactDocs1(empIndex: number) {
    this.extraDetails = this.contactPersonArray['controls'][empIndex]['controls']['extraDetails'] as FormArray

    this.extraDetails.push(this.createDocument())
    // //console.log('this.contactPersonDocList',this.contactPersonArray['controls'][0]['controls']['extraDetails'])
  }
  addContactSocialLink(empIndex: number) {
    this.socialMediaLink = this.contactPersonArray['controls'][empIndex]['controls']['socialMediaLink'] as FormArray

    this.socialMediaLink.push(this.createSocialLink())
    // //console.log('this.contactPersonDocList',this.contactPersonArray['controls'][0]['controls']['socialMediaLink'])
  }

  getDocArrayControls(index) {
    return (<FormArray>(<FormArray>this.ContactPersonForm.controls.contactPersonArray).controls[index].get('extraDetails')).controls
  }
  addContactFunc() {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    if (this.contactPersonArray.length == 0) {
      this.contactSubmitted = false;
      this.contactPersonArray.push(this.createContact());
    } else if (this.contactPersonArray.length > 0) {
      this.contactSubmitted = true;

      if (this.contactSubmitted && !this.ContactPersonForm.invalid) {
        this.contactListValueArray.push(...this.ContactPersonForm.controls.contactPersonArray.value)
        this.contactSubmitted = false
        this.contactPersonArray.push(this.createContact());

      }
    }


  }
  removeContacFunc(index) {

    this.contactPersonArray.removeAt(index)
    // this.contactPersonArray.removeAt(this.contactPersonArray.value.findIndex(image => image.id === index) )
  }
  get cf() { return this.ContactPersonForm.controls; }

  getAddressByZipCode(value, type) {

    let params = new HttpParams().set('?zipcode', value)
    this.commonService.getWithParms('zipcode', params).subscribe(res => {
      if (res.success) {
        // //console.log('zip code data',res.data)
        this.getCountries()

        if (res.data) {
          this.country = res.data.country

          setTimeout(() => {
            let countryValue = this.countries.find(x => x.shortName == this.country)

            this.commonAddClientForm.controls.country.setValue(this.country)


            if (res.data.state) {
              this.state = res.data.stateShortName
              this.getStates('')
              // let stateValue = this.states.find(x => x.shortName == this.state)
              // // //console.log('stateValue',stateValue,this.state)


              this.commonAddClientForm.controls.state.setValue(res.data.stateShortName)


            }
            if (res.data.city) {

              this.city = res.data.city


              this.commonAddClientForm.controls.city.setValue(this.city)

              this.getCities()
            }
          }, 1000);
        }
      } else {
        this.toastr.error(res.message)
      }
    }, error => {
      this.toastr.error(error)
    })
  }

  getCountries() {
    this.commonService.get('countrys').subscribe(res => {
      if (res.success) {
        this.countries = res.data
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
    },
      error => {
        //  this.toastr.error(error, 'Error');
      }
    );
  }
  getStates(value) {
    this.countryShortName = this.countries.find(x => x.name == this.country)
    let data = new HttpParams().set('?shortName', this.countryShortName && this.countryShortName.shortName)

    this.commonService.getWithParms('states', data).subscribe(res => {
      if (res.success) {
        this.states = res.data
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
    },
      error => {
        //  this.toastr.error(error, 'Error');
      }
    );
  }
  getCities() {
    let data = new HttpParams().set('?state', this.state).set('shortName', this.countryShortName && this.countryShortName.shortName)

    // {
    //   state: this.state,
    //   shortName: this.countryShortName && this.countryShortName.shortName
    // }

    this.commonService.getWithParms('cities', data).subscribe(res => {
      if (res.success) {
        this.cities = res.data
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
    },
      error => {
        //  this.toastr.error(error, 'Error');
      }
    );
  }
  AuctionSubmit() {


    this.commonSubmitted = true;
    // //console.log('auction form value',this.commonAddClientForm.controls)
    if (!this.commonSubmitted && this.commonAddClientForm.valid) {
      return
    }
    if (this.commonSubmitted && !this.commonAddClientForm.invalid) {
      this.spinner.show();
      let mobileval = this.commonAddClientForm.controls.countryCode.value
      let mobilevalue = this.commonAddClientForm.controls.mobileNo.value
      let mobilecode = mobileval + mobilevalue
      let data = {

        companyName: this.commonAddClientForm.value.companyName,
        companyId: this.commonAddClientForm.value.companyId,
        clientType: this.commonAddClientForm.value.clientType,
        firstName: this.commonAddClientForm.value.firstName,
        lastName: this.commonAddClientForm.value.lastName,

        email: this.commonAddClientForm.value.email,

        mobileNo: mobilecode,
        phoneNumberDetails: {
          mobileNo: this.commonAddClientForm.value.mobileNo,
          countryCode: this.commonAddClientForm.value.countryCode,
        },
        address: {
          address1: this.commonAddClientForm.value.address1,
          address2: this.commonAddClientForm.value.address2,
          country: this.commonAddClientForm.value.country,
          city: this.commonAddClientForm.value.city,
          state: this.commonAddClientForm.value.state,
          zipCode: this.commonAddClientForm.value.zipCode,
        },
        meetingData: {
          lastMeeting: this.commonAddClientForm.value.lastMeeting,
          nextMeeting: this.commonAddClientForm.value.nextMeeting,

        }

      }
      if (this.commonAddClientForm.value.clientType == 'business') {
        data['clientInfoDetails'] = {
          clientType: this.commonAddClientForm.value.clientType,
          companyName: this.commonAddClientForm.value.companyName
        }
      }
      let contactData: any;
      //       let websiteData: any;
      //       let websiteDataList=[];
      //       if(this.commonAddClientForm.value.websiteArray){
      //         websiteData= this.commonAddClientForm.value.websiteArray
      //       }
      //       websiteData.forEach(element => {
      // let data = {
      //   website:element.website
      // }
      // websiteDataList.push(data)
      //       })
      if (this.ContactPersonForm.valid) {
        contactData = this.ContactPersonForm.value.contactPersonArray
        let contactDataList = [];
        let contactDocList = [];
        let socialLinkList = [];
        contactData.forEach(element => {
          if (element.extraDetails.length > 0) {
            element.extraDetails.forEach(elem => {
              let elemData = {
                docTitle: elem.docTitle,
                progressBar: elem.progressBar,
                docs: elem.docs
              }
              contactDocList.push(elemData)
            });
          }
          if (element.extraDetails && element.extraDetails.socialMediaLink && element.extraDetails.socialMediaLink.length > 0) {
            element.extraDetails.socialMediaLink.forEach(elem => {
              let elemData = {
                link: elem.link,

              }
              socialLinkList.push(elemData)
            });
          }
          let data = {
            companyId: element.companyId,
            email: element.email,
            firstName: element.firstName,
            lastName: element.lastName,
            mobileNo: element.contactcountryCode + element.contactmobileNo,
            phoneNumberDetails: {
              mobileNo: element.contactmobileNo,
              contactcountryCode: element.contactcountryCode
            },
            note: element ? element.note:'',
            progressBar: element.progressBar,
            role: element.role,
            title: element.title,
            documentUpload: {
              extraDetails: contactDocList,

            },
            extraDetails: {
              profileImage: element.profileImage,
              socialMediaLink: socialLinkList
            }
          }
          contactDataList.push(data)
        });
        data['contact_persons'] = contactDataList
        // data['websiteData']=websiteDataList

      }
      // //console.log('submitted dta',data)

      if (this.conditionalForm) {
        data['id'] = this.autionData.id
        data['email'] = this.autionData.email
        let params = new HttpParams().set('?id', this.autionData.id)
        this.commonService.updatePut('user', data, params).subscribe(res => {

          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');

            this.commonService.clientAdded.next(true)
            this.commonAddClientForm.reset();
            this.commonAddClientForm.controls.countryCode.setValue('+1')
            this.commonSubmitted = false
            this.spinner.hide()
          } else {
            this.toastr.error(res.error.message, 'Error');
            this.spinner.hide()
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            this.toastr.error(error, 'Error');

          });

      } else {
        this.commonService.add('client', data).subscribe(res => {
          let url;
          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');
            this.commonSubmitted = false
            this.commonService.clientAdded.next(true)
            this.commonAddClientForm.reset();
            this.commonAddClientForm.controls.countryCode.setValue('+1')
            this.spinner.hide()
          } else {
            this.toastr.error(res.error.message, 'Error');
            this.spinner.hide()
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            // this.toastr.error(error, 'Error');
          });
      }
    }
  }




  roleTitleFormSubmit() {
    this.roleTitleSubmitted = true;
    if (!this.roleAndTitleForm.invalid) {
      let data = {
        name: this.roleAndTitleForm.value.name,
        userType: this.userType
      }
      let titleValue = ''
      if (this.addType == 'title') {
        titleValue = 'Title'
      } else if (this.addType == 'role') {
        titleValue = 'Role'
      }
      // if (this.addType == 'category') {
      //   titleValue='Category'
      // }
      this.spinner.show()
      this.commonService.add(this.addType, data).subscribe(res => {

        if (res.success) {
          this.toastr.success(titleValue + ' Added Successfully!');
          if (this.addType == 'title') {
            this.getTitleList()
          } else if (this.addType == 'role') {
            this.getRoles()
          }
          // if (this.addType == 'category') {
          //   this.getCategoryFunc()
          // }
          this.roleModal = false
          this.spinner.hide()
          this.roleAndTitleForm.reset()
        } else {
          this.toastr.error(res.error.message, 'Error');
          this.spinner.hide();
        }

        this.roleModal = false
      },
        error => {
          this.spinner.hide();
          this.roleModal = false
          // this.toastr.error(error, 'Error');
        }
      );
    }
  }
  getTitleList() {
    let data = new HttpParams().set('?userType', this.userType)
    this.commonService.getWithParms('title/name', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.titleListData = [];
      } else {
        this.titleListData = response.data;
        this.roles.forEach(element => {
          if (element.name != "" && element.name == null && element.name !== undefined) {
            this.titleListData.push(element)
          }
        });
      }
    });
  }
  uploadContactProfileImages(files: FileList, i) {
    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {

        this.fileToUpload = files.item(i)
      }
    }
    //  this.fileToUpload = files.item(0)

    // let type = 'blogs'
    // let object = {
    //   data: imageResult.dataURL,
    //   type: 'users'
    // }
    let type: 'users'
    // this.myInputVariable.nativeElement.value = "";
    if (this.fileToUpload) {
      this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('true')



      this.commonService.uploadOnlyImage(this.fileToUpload, 'users').subscribe((event: HttpEvent<any>) => {

        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // // //console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // // //console.log('User successfully created!', event.body);
            if (event.body.success) {
              let docData = '';
              docData = event.body.data.imagePath
              this.contactPersonArray.controls[i]['controls']['profileImage'].patchValue(docData)
              // this.commonAddClientForm.patchValue({ image: this.userImage }) 

              this.contactProfileInput.nativeElement.value = '';
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            }
            setTimeout(() => {
              this.progress = 0;
              this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')
            }, 100);

        }

        // this.imageLoader=false;
      }, err => {
        this.contactPersonArray.controls[i]['controls']['progressBar'].patchValue('false')

        // this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }
  }
  addRoleFunc(value) {
    this.addType = value
    this.roleModal = true
  }
  uploadContactDocs(files, pIndex, i) {
    if (files) {
      const formData: FormData = new FormData();
      let uploadedImageArray: any = []
      // this.fileToUpload = files.item(0)
      for (let index = 0; index < files.length; index++) {
        let element = files[index];
        uploadedImageArray.push(element)
        this.fileToUpload = uploadedImageArray;
        formData.append('data', this.fileToUpload[index], this.fileToUpload[index].name);
      }
      formData.append('modelName', 'documents');
      // //console.log('uploadd array', formData)
      // let object = {
      //   data: files.item(0),
      //   type: 'category'
      // }
      // this.myInputVariable.nativeElement.value = "";


      let docData = []
      docData = this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].value


      this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('true')
      let params = new HttpParams().set('?modelName', 'documents')
      this.commonService.uploadMultipleImage(params, formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // // //console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // // //console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // // //console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // // //console.log('User successfully created!', event.body);
            if (event.body.success) {

              if (docData.length > 0) {
                docData.push(...event.body.data.imagePath)
                this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].patchValue(docData)


              } else {
                docData = event.body.data.imagePath;
                // //console.log(i); 
                this.contactPersonDocList(pIndex).controls[i]['controls']['docs'].patchValue(docData)

              }

              // this.commonAddClientForm.patchValue({ image: this.userImage }) 
              this.uploadDocs.nativeElement.value = '';
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            }
            setTimeout(() => {
              this.progress = 0;
              this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

            }, 100);

        }

      }, err => {
        this.contactPersonDocList(pIndex).controls[i]['controls']['progressBar'].patchValue('false')

        // this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });
    }

  }

  removeUploadFile(index) {
    this.extraDetails.removeAt(index)
  }
  removeSocialLink(index) {
    this.socialMediaLink.removeAt(index)
  }
  removeImage(image, index) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;

    // //console.log('this.contactPersonArray',this.contactPersonArray)

    let object = {
      Imagename: image,
      type: 'users'
    }
    this.commonService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {
        this.contactPersonArray.controls[index]['controls']['profileImage'].patchValue('')

        this.spinner.hide();
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  removeDocument(image, ContactIndex, index) {
    this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
    this.extraDetails = this.contactPersonArray['controls'][ContactIndex]['controls']['extraDetails'] as FormArray

    // //console.log('this.extraDetails',this.extraDetails)
    // //console.log('this.extraDetails.controls[index]',this.extraDetails.controls[index]['controls']['docs'].value)
    let docValue = this.extraDetails.controls[index]['controls']['docs'].value

    let resultValue = []
    resultValue = docValue.filter(item => item !== image)

    let object = {
      Imagename: image,
      type: 'documents'
    }

    this.commonService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {
        // //console.log('this.extraDetails.controls[index]',this.extraDetails.controls[index]['controls']['docs'])
        this.extraDetails.controls[index]['controls']['docs'].setValue(resultValue)
        // this.contactPersonDocList(index).controls[fileIndex]['controls']['docs'].patchValue('')

        this.spinner.hide();
      } else {
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  getData() {

    this.spinner.show()

    let data = new HttpParams().set('?id', this.userID)
    this.commonService.getWithParms('user/details', data).subscribe((response) => {
      if (response.success) {
        this.autionData = response.data;
        // //console.log('firstName',this.autionData)
        this.spinner.hide()

        if (this.autionData.contact_persons) {
          this.contactPersonArray = this.ContactPersonForm.get('contactPersonArray') as FormArray;
          // this.websiteArray = this.commonAddClientForm.get('websiteArray') as FormArray;

          let ContactPersonData = this.autionData.contact_persons
          // let websiteDataListing=[]
          // let websiteComingData = this.autionData.websiteData?this.autionData.websiteData:[]
          // if(websiteComingData.length>0){
          //   websiteComingData.forEach(element => {
          //     websiteDataListing.push({
          //       website: element.website ? element.website : '',
          //     })
          //   });
          // }

          let ContactData = []
          if (ContactPersonData.length > 0) {
            ContactPersonData.forEach(element => {

              ContactData.push({
                title: element.title ? element.title.id : '',


                firstName: element.firstName ? element.firstName : '',
                lastName: element.lastName ? element.lastName : '',
                email: element.email ? element.email : '',
                contactmobileNo: element.phoneNumberDetails ? element.phoneNumberDetails.contactmobileNo : '',
                contactcountryCode: element.phoneNumberDetails ? element.phoneNumberDetails.contactcountryCode : '+1',
                note: element.note ? element.note : '',
                progressBar: element.progressBar ? element.progressBar : 'false',
                role: element.role ? element.role.id : '',
                // companyId:element.companyId?element.companyId:'',
                profileImage: element.extraDetails ? element.extraDetails.profileImage : '',
                headline: element.documentUpload ? element.documentUpload.headline : '',
                extraDetailsData: element.documentUpload ? element.documentUpload.extraDetails : [],
                soicialLinkData: element.extraDetails ? element.extraDetails.socialMediaLink : [],
                extraDetails: this.formBuilder.array([]),
                socialMediaLink: this.formBuilder.array([]),
              })

            });
          }
          // //console.log('ContactData after push', this.contactPersonArray) 
          // websiteDataListing.forEach(w => {
          //   let i =    websiteDataListing.indexOf(w)
          //   this.websiteArray.push(this.formBuilder.group(w));
          // })
          // if(websiteDataListing.length>=1){
          //   this.websiteArray.removeAt(0) 
          // }else{
          //   this.websiteArray.push(this.createWebsite())
          // }
          ContactData.forEach(x => {
            let i = ContactData.indexOf(x)
            this.contactPersonArray.push(this.formBuilder.group(x));
            // this.contactPersonDocList(i)
            this.extraDetails = this.contactPersonArray['controls'][i]['controls']['extraDetails']
            this.socialMediaLink = this.contactPersonArray['controls'][i]['controls']['socialMediaLink']

            if (x.extraDetailsData != null && x.extraDetailsData != undefined && x.extraDetailsData.length > 0) {
              x.extraDetailsData.forEach(y => {
                // //console.log('y data',y) 
                let yData = {
                  docTitle: y.docTitle ? y.docTitle : '',
                  progressBar: y.progressBar ? y.progressBar : 'false',
                  docs: [y.docs ? y.docs : []]
                }
                this.extraDetails.push(this.formBuilder.group(yData))
                // this.socialMediaLink.push(this.formBuilder.group(yData))

              });

            }
            if (x.soicialLinkData != null && x.soicialLinkData != undefined && x.soicialLinkData.length > 0) {
              x.soicialLinkData.forEach(z => {
                // //console.log('z data',z) 
                let zData = {
                  link: z.link ? z.link : '',
                }
                this.socialMediaLink.push(this.formBuilder.group(zData))

              });
              if (x.soicialLinkData.length > 2) {
                this.socialMediaLink.removeAt(0)
              }
            }
          });
          // //console.log('this.extraDetails patch',this.extraDetails)
          // //console.log('this.contactPersonArray',this.contactPersonArray)
          // this.socialMediaLink.removeAt(0) 
          //     this.websiteArray.removeAt(0) 
        }
        this.country = this.autionData.address.country
        this.state = this.autionData.address.state
        this.getStates('')
        this.getCities()


        setTimeout(() => {
          this.commonAddClientForm.patchValue({
            clientType: this.autionData.clientType,
            firstName: this.autionData.firstName,
            lastName: this.autionData.lastName,
            companyName: this.autionData.clientInfoDetails ? this.autionData.clientInfoDetails.companyName : '',
            // dealer:this.commonAddClientForm.value.dealer,
            companyId: this.autionData.companyId,
            lastMeeting: this.autionData.meetingData ? this.autionData.meetingData.lastMeeting : '',
            nextMeeting: this.autionData.meetingData ? this.autionData.meetingData.nextMeeting : '',
            note: this.autionData ? this.autionData.note:'',
            country: this.autionData.address.country,
            state: this.autionData.address.state,
            city: this.autionData.address.city,
            address1: this.autionData.address.address1,
            address2: this.autionData.address.address2,
            zipCode: this.autionData.address.zipCode,

            email: this.autionData.email,
            mobileNo: this.autionData.phoneNumberDetails ? this.autionData.phoneNumberDetails.mobileNo : '',
            countryCode: this.autionData.phoneNumberDetails ? this.autionData.phoneNumberDetails.countryCode : '',



          })


        }, 1000)

      } else {
        this.spinner.hide()
        this.toastr.error(response.error)
      }
    }, error => {
      this.spinner.hide()
    });
  }
  closeModal() {
    this.roleModal = false;
    this.commonSubmitted = false;
    this.commonService.clientAdded.next(false)

  }

  ngOnChanges(changes: SimpleChanges): void {
    // //console.log('changes data',changes)

    if (changes.commingData.currentValue !== '' && changes.commingData.currentValue !== undefined && changes.commingData.currentValue !== null) {
      // //console.log('changes data',changes)
      this.clientType = changes.commingData.currentValue
    }


  }

}
