import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeDecimal'
})

export class removeDecimal implements PipeTransform {
    transform(value): any {
       let item = Math.round(value)
        return item;
    }
}