import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';  
import { HttpParams } from '@angular/common/http'; 
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-buying-details',
  templateUrl: './buying-details.component.html',
  styleUrls: ['./buying-details.component.css']
})
export class BuyingDetailsComponent implements OnInit {
  offerDetailsValue:boolean=false
  @Input() myDetailData:any; 
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  prospectingID:any;
  logView:boolean=false;;
  _host=environment.url;
  pageNo:any;
  prospectiveId:any;
  prospectingData:any;
  loader:boolean=false
  constructor( private router: Router,
    private _activateRouter: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService, 
    private commonService: CommonService,
    config: NgbCarouselConfig) { 
    this.prospectingID = this._activateRouter.snapshot.params['id'];
    // console.log('this._activateRouter.snapshot.params',this._activateRouter.snapshot.params['offer'])
    this.pageNo = this._activateRouter.snapshot.params['page'];
    
    this.prospectiveId = this._activateRouter.snapshot.params['prospectiveId'];
    // console.log(this.router.url)
    if(this.router.url.match(/view/)){
this.logView=true
    }
    if(this.router.url.match(/offer/)){
      this.offerDetailsValue = true
          }else{
            this.offerDetailsValue = false
          }
  }

  ngOnInit() {
    if(this.offerDetailsValue==true){ 
  
      this.getProspectingFunc()
    }
  }

  openWindow(url,no){
    window.open(url,+'?='+no+'newwindow', 'width=1200,height=1000',)
 
    return false;;

  }
  getProspectingFunc() {
    this.loader=true; 
  
    // let data = new HttpParams().set('?search',this.search).set('isDeleted',JSON.stringify(this.isDeleted)).set('page',JSON.stringify(this.page))
let params = new HttpParams().set('/',this.prospectingID)

  this.commonService.get('buy_prospecte/'+this.prospectingID).subscribe((response) => { 
        
        // console.log('this.prospectingData',this.prospectingData) 
        if (response.success) {
          this.prospectingData = response.data 
          this.loader=false
          // console.log('this.prospectingData',this.prospectingData) 
      }else{
        this.toastr.error(response.error.message, 'Error');
        this.loader=false
      }
      },
      error => {
        this.loader=false
        // this.toastr.error(error, 'Error');
      });
  
  }

  ngOnChanges(changes: SimpleChanges): void { 
    // console.log('changes data',changes)
 
        if(changes.myDetailData.currentValue!==''){
          this.prospectingID =changes.myDetailData.currentValue
          this.getProspectingFunc()
          // // console.log('changes data',changes)
          // if(changes.commingData.currentValue.search!='' && changes.commingData.currentValue.search!=null && changes.commingData.currentValue.search!=undefined){
          //   this.search = changes.commingData.currentValue.search 
          //   this.searchValue()
          // } 
          // else{
          //   this.search='' 
          //   this.getProspectingFunc()
            
          // }  
       
        }  

       
      }

}
