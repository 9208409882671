export class StateData {
    user: any;
    damageTypeID: string;
    damageTypeName: string;
    damageType: string;
    contactPersonData: any;
    conditionReportId: string;
    contactFormFooter: boolean;
    contactPerson:any;
    constructor(){
        this.contactFormFooter = false
    }
}