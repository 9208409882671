import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'innerHtml'
})

export class innerHtml implements PipeTransform {
    transform(text: any, length: number = 50, showAll: boolean = false, suffix: string = '...'): any {
    
        if (showAll) {
          return text;
        }
    
        if ( text.split(" ").length > length ) {
          
          return text.split(" ").splice(0, length).join(" ") + suffix;
        }
    
        return text;
      }
}