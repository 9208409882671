 
import { Component, Input, OnInit, SimpleChanges } from '@angular/core'; 
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';  
import { CommonService } from 'src/app/services/common.service'; 
import { HttpParams } from '@angular/common/http'; 

@Component({
  selector: 'app-shared-timeline',
  templateUrl: './shared-timeline.component.html',
  styleUrls: ['./shared-timeline.component.css']
})
export class SharedTimelineComponent implements OnInit {

  @Input() commingData:any
  totalItems:number; 
	totalPages: any;
   prospectingId:any=''
   status:any=''
   shortBy:any=''
	page=1
	recordsPerPage =10;
	activePage: number = 1;
	totalPagesArray:any=[];
  clientList:any=[]
  loader:boolean=false
  timelineList = [];
  search:any=''
  graphicModal:boolean=false
  userType:any=''
  userId:any=''
  prospecteId:any=''
  ColumnMode = ColumnMode;
  clientListData = []
  singleTimelineData:any=[] 
  columns = []; 
  clientId:any=''
  prospectingList:any=[]
  partnerClientList:any=[]
  user_type = '' 
  pageNo: number = 1;
  constructor(
    private spinner: NgxSpinnerService,  
    private commonService:CommonService, 
  ) {
    if(localStorage.getItem('credentials')){
      let userData = JSON.parse(localStorage.getItem('credentials'))
      this.userType =userData.role
      this.clientId = userData.id
    }

   }

  ngOnInit() {
  
  }
  
  getTimelineFunc() {
      this.loader=true   
      let params = new HttpParams().set('?prospecteId',this.prospecteId).set('status',this.status)
      .set('shortBy',this.shortBy).set('userType',this.user_type).set('clientId',this.clientId).set('search',this.search).set('page',JSON.stringify(this.page))
      this.commonService.getWithParms('multi/offer/timeline/user',params).subscribe((res:any) => {     
        if(res.success){
          let totalRecord=0
          this.timelineList = res.data;
          totalRecord = res.total
          this.totalItems = res.total
    
    
        if (totalRecord > 0 && this.recordsPerPage > 0) {
          const pageCount = totalRecord / this.recordsPerPage;
          const roundedPageCount = Math.floor(pageCount);
    
          this.totalPages = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
        }
        // //console.log('this.totalPages',this.totalPages,totalRecord)
    
  
          this.loader=false
        }  
        this.loader=false 
      },
        error => {
          this.loader=false
        }
      ); 
  }

          
  searchValue() {
    this.pageNo = 1; 
    this.getTimelineFunc();
  }

  clearValue() {
    this.pageNo = 1; 
    this.search=''
   
    this.getTimelineFunc();
  }


	onClickPage(pageNumber: number) {
		this.page = pageNumber
		this.page=pageNumber;
		if (pageNumber >= 1 && pageNumber <= this.totalPages) {
		  this.activePage = pageNumber;
		  if(this.userType=='exporting_agent'){ 
        // //console.log('this.clientId2',this.userId)
        if(this.userId!='' && this.userId!=null){
          // this.geAgenttProspective(this.userId);
        } 
      }else{ 
        this.getTimelineFunc();
      } 
		  // this.onPageChange.emit(this.activePage);  
		}
	  }

    ngOnChanges(changes: SimpleChanges): void { 
      // //console.log('changes data 33',changes)
   
          if(changes.commingData.currentValue!=='' && changes.commingData.currentValue!==undefined && changes.commingData.currentValue!==null){
            // //console.log('changes data',changes)
            this.prospecteId = changes.commingData.currentValue.id
            this.clientId = changes.commingData.currentValue.clientId
              
            this.getTimelineFunc()
         
          }  
  
         
        }

}
