import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-emials',
  templateUrl: './emials.component.html',
  styleUrls: ['./emials.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmialsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EmialsComponent,
      multi: true
    }
  ]
})
export class EmialsComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() emails: string[] = [];
  @Input() placeholder: string = "Enter email address";
  @Output() emailsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChildren("emailItem") emailItems: QueryList<ElementRef>;

  emailControl: FormControl;
  isEntered: boolean;

  private focusedItemIndex: number;
  private propagateChange = (_: any) => { };

  @HostListener("document:keydown.escape", ["$event"])
  onEscPressed(event) {
    if (this._elRef) {
      const found: ElementRef = this.emailItems.find(
        (item, index) => index === this.focusedItemIndex
      );
      if (found) {
        found.nativeElement.blur();
      }
    }
  }

  constructor(private _elRef: ElementRef) { }
  validate(control: AbstractControl): ValidationErrors {
    return
  }

  registerOnValidatorChange?(fn: () => void): void {
    throw new Error("Method not implemented.");
  }

  writeValue(emails: string[]): void {
    if (emails) {
      this.emails = emails;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.emailControl = new FormControl("", [
      Validators.required,
      Validators.email, Validators.pattern(re),
      duplicatedEmailValidator(this.emails)
    ]);
  }

  onEnterPressed(event) {
    this.isEntered = true;
    if (this.emailControl.invalid) return;

    this.emails.push(this.emailControl.value);
    this.emailsChange.emit([...this.emails]);
    this.propagateChange([...this.emails]);
    this.emailControl.reset();
    this.isEntered = false;
  }

  onBackspacePressed(event) {
    if (this.emailControl.value || !this.emails.length) return;
    if (this.focusedItemIndex == null) {
      this.focusedItemIndex = this.emailItems.length - 1;
      this.emailItems.last.nativeElement.focus();
    } else {
      this.removeEmailItem(this.focusedItemIndex);
      setTimeout(() => {
        this.focusedItemIndex =
          this.focusedItemIndex - 1 < 0 ? 0 : this.focusedItemIndex - 1;

        const found = this.emailItems.find(
          (item, index) => index === this.focusedItemIndex
        );
        if (found) {
          found.nativeElement.focus();
        }
      });
    }
  }

  onArrowLeftPressed(event) {
    if (this.focusedItemIndex == null) return;
    const newIndex =
      this.focusedItemIndex - 1 < 0 ? 0 : this.focusedItemIndex - 1;

    const found = this.emailItems.find((item, index) => index === newIndex);
    if (found) {
      found.nativeElement.focus();
    }
  }

  onArrowRightPressed(event) {
    if (this.focusedItemIndex == null) return;
    const newIndex =
      this.focusedItemIndex + 1 > this.emailItems.length
        ? this.emailItems.length - 1
        : this.focusedItemIndex + 1;
    const found = this.emailItems.find((item, index) => index === newIndex);
    if (found) {
      found.nativeElement.focus();
    }
  }

  removeEmailItem(index: number) {
    this.emails.splice(index, 1);
    this.emailsChange.emit([...this.emails]);
    this.propagateChange([...this.emails]);
  }

  onEmailItemFocused(event, index: number) {
    this.focusedItemIndex = index;
  }

  onInputFocused(event) {
    this.focusedItemIndex = null;
  }
}

const duplicatedEmailValidator = (emails: string[]): ValidatorFn => {
  return (control: FormControl): ValidationErrors => {
    if (!emails.length) return;
    const email = control.value;
    if (!email) return;
    return emails.includes(email.trim()) ? { duplicatedEmail: true } : null;
  };
};
