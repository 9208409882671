import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BehaviorService {

    public userData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public tabData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public dashData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public modalclass: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public requestTabData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public worklistTabData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public clientModal: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public trimData: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public redirection: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public totalCount: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public search: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public resfreshData: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public seachData: BehaviorSubject<any> = new BehaviorSubject<object>({}); 
    public NotificationTotle:BehaviorSubject<any> = new BehaviorSubject<object>({})
    public callPrreOrderApiData: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public closecommonmodule=new BehaviorSubject<any>(false)
    public  showOfferData:BehaviorSubject<any>=new BehaviorSubject<any>(false)
    
    public is_solid:BehaviorSubject<any> = new BehaviorSubject<object>({})


    rootUrl: string = environment.url;

    constructor(private spinner: NgxSpinnerService) { }


    setUserData(data) {
        this.userData.next(data);
    }
    getUserData() {
        return this.userData.asObservable();
    }

    
    setTabid(data) {
        //console.log(data,'578869507')
        this.tabData.next(data);

    }
    getTabid() {
        return this.tabData.asObservable();
    }

    setDashTab(data) {
        //console.log(data,'ffffffffffffffffffffffffff')
        this.dashData.next(data);

    }
    getDashTab() {
        //console.log(this.dashData,'77777777777777')
        return this.dashData.asObservable();
    }

    setModalclass(data) {
        //console.log(data,'class')
        this.modalclass.next(data);

    }
    getModalclass() {
        return this.modalclass.asObservable();
    }

    setRequestTabid(data) {
        //console.log(data,'requestid')
        this.requestTabData.next(data);

    }
    getRequestTabid() {
        return this.requestTabData.asObservable();
    }
    // setWorklistTabid(data) {
    //     //console.log(data,'worklistid')
    //     this.worklistTabData.next(data);

    // }
    // getWorklistTabid() {
    //     return this.worklistTabData.asObservable();
    // }
    
    setTrim(trimData) {
        this.trimData.next(trimData)
    }

    setSearchDatas(data) {
        this.seachData.next(data);

    }
    getSeacrchData() {

        return this.seachData.asObservable();

    }
    conditions(data){
this.closecommonmodule.next(data)
    }
    retunconditions(){
        return this.closecommonmodule.asObservable()
    }
    closeModal(data) {
        this.totalCount.next(data)
    }
    getModals() {
        return this.totalCount.asObservable();

    }
    closeModals(data) {
        this.clientModal.next(data)
    }
    getModales() {
        return this.clientModal.asObservable(); 
    }
    refreshData(data) {
        this.resfreshData.next(data)
    }
    callPrreOrderApi(data){
       this.callPrreOrderApiData.next(data)
    }
    returncallPrreOrderApi() {
        return this.callPrreOrderApiData.asObservable(); 
    }
    NotificationCount(data){
        this.NotificationTotle.next(data)
    }
    totleNoti(){
      return this.NotificationTotle.asObservable()
    }

    getrefreshData() {
        return this.resfreshData.asObservable();
    }
    getTrim() {
        return this.trimData.asObservable();
    }
    setDirestion(data) {
        this.redirection.next(data)
    }
    getDirestion() {
        return this.redirection.asObservable();
    }
    setSearch(search) {
        this.search.next(search)
    }
    getSearch() {
        return this.search.asObservable();
    }

    getTab() {
        return this.is_solid.asObservable();
    }
    setTab(data) {
        this.is_solid.next(data)
        //console.log(this.is_solid)
    }
    public getUser(): any {
        const user = localStorage.getItem('credentials');
        if (user) {
            return JSON.parse(user);
        }

        return null;
    }

    showoffer(data){
        this.showOfferData.next(data)
    
    }

    returnshareoffer(){
         
       return  this.showOfferData.asObservable()
    }



    load(p) {

        if (p) {
            this.spinner.show()
        } else {
            this.spinner.hide()
        }

    }

    getModal() {
        return this.clientModal.asObservable();

    }


    dayCount(item) {

        // Day Calculator between 2 dates 
        item = new Date(item)
        let today = new Date()
        let startDate = new Date(item);
        let endDate = new Date(today);

        let timeDifference = startDate.getTime() - endDate.getTime();
        let dayMilliSeconds = 1000 * 60 * 60 * 24;
        let totalDays = Math.abs(timeDifference / dayMilliSeconds); // it returns negative value if start date < end date
        totalDays = Math.floor(totalDays);

        let diff = endDate.valueOf() - startDate.valueOf();
        let diffInHours: any = diff / 1000 / 60 / 60;
        let hours = diffInHours.toFixed(0)

        let dayString: any
        if (item > today) {
            if (totalDays == 0) {
                dayString = 'In ' + hours + ' Hours'
            } else {
                dayString = 'In ' + totalDays + ' Days'
            }
        } else {
            if (totalDays == 0) {
                dayString = hours + ' Hours'
            } else {
                dayString = totalDays + ' Days' + ' Ago'
            }
        }
        return dayString
    }


    UtcToObject(val) {

        if (val && val.day) {
            let dd: any
            if (val != null && val && val.year) {
                dd = val ? new Date(val ? val.year : 0, val ? val.month -1: 0, val ? val.day : 0,) : ''
    
            }
            // //console.log(dd,'rr date')
            return dd
        } else {
    
            return val
        }
    
    }

    utcToObject(newDate) {
        // //console.log(newDate,'newDate');
        if(newDate !=null &&  newDate !=undefined && newDate !=""){
            newDate  = new Date(newDate)
            var month = newDate.getUTCMonth() + 1; //months from 1-12
            var day = newDate.getUTCDate();
            var year = newDate.getUTCFullYear();
            let val = { 'year': year, 'month': month, 'day': day }
            return val
        }
       
    }
    GetDays(item) {

        // Day Calculator between 2 dates 
        let today = new Date()
        let startDate = new Date(today);
        let endDate = new Date(item);
        let timeDifference = startDate.getTime() - endDate.getTime();
        let dayMilliSeconds = 1000 * 60 * 60 * 24;
        let totalDays = Math.abs(timeDifference / dayMilliSeconds); // it returns negative value if start date < end date
        totalDays = Math.floor(totalDays);

        // //console.log(totalDays,'totalDays');

        return totalDays

    }

}
