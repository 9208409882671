import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.css']
})
export class ContactCardComponent implements OnInit {
  userDatas:any;
  userID:any;
  public _host = environment.url;
  constructor(
    private commonService: CommonService,
    private _activateRouter: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit() {
    this.userID = this._activateRouter.snapshot.params['id'];
  console.log(this.userID,'this.userID');
  this.contactData()

  }


  contactData() {
    let params = new HttpParams().set('?id', this.userID)
    this.commonService.getWithParms('user/details', params).subscribe(res => {
      if (res.success) {
        this.userDatas = res.data
      }
    })

  }
}
