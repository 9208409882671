import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; 
import { ContactCardComponent } from './contact-card/contact-card.component';
// Layouts
// import { ThemeModule } from './theme/theme.module';

const routes: Routes = [
  
  {
    path: '',
    loadChildren: './theme/theme.module#ThemeModule',
    // loadChildren:() =>  import('./theme/theme.module').then(m => m.ThemeModule)
  },
  {
    path: 'contactcard/:id',
    component: ContactCardComponent
  }, 
 
  // {path: '**', redirectTo: '/auth/admin/login'}
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [ RouterModule,HttpClientModule ]
})
export class AppRoutingModule { }
