import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError,delay } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject,of } from 'rxjs'; 
import { environment } from '../../environments/environment';
import { Socket } from "ngx-socket-io";
import { ListComponent } from '../news/list-component/list.component';
const URL = "https://endpoint.epicbusinessgroup.us";
const companyData : any=[]; 
class PagedData<T> {
  data: T[];
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  actionType = new BehaviorSubject('active')
  addedById = new BehaviorSubject('')
  addedByRole = new BehaviorSubject('')
  prospectiveId = new BehaviorSubject('')
  editClientData = new BehaviorSubject('');
  trimEditData = new BehaviorSubject('');
  adddocumentModal= new BehaviorSubject(false)
  addRequestModal= new BehaviorSubject(null)
  contentUpdate =  new BehaviorSubject(null)
addBankModal= new BehaviorSubject({
  modal:null,
  id:''
})
appStatusUser = new BehaviorSubject(false)
  addConditionalModal= new BehaviorSubject(false)
  addConditionalID= new BehaviorSubject('')
  dealerID= new BehaviorSubject(null)
  messageCounter = new BehaviorSubject(0);  
  clientAdded= new BehaviorSubject(false)
  trimAdded= new BehaviorSubject(false)
  trimEdit = new BehaviorSubject({ 
    modelId:'',
    trimId:''
  })
  commoncontactPersonModal= new BehaviorSubject(false)
  conditionalReportData = new BehaviorSubject ('')
  contactUserType = new BehaviorSubject ('')
 addOfferConditionalReport = new BehaviorSubject(false)
  private _baseUrl = environment.url;


  pinCheckData: BehaviorSubject<object> = new BehaviorSubject<object>({
    pinModal: false,
    otpView: true,
    route: '',
    verifyOTP: false
  });

  constructor(
    private socket: Socket,
    private httpClient: HttpClient) {
      this.contactUserType.subscribe(res=>{  
          })
     
  }

  uploadMultipleVideo(formData,params) {
    return this.httpClient.post(this._baseUrl + `uploadvideos`+params,formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public getResults(offset: number, limit: number): Observable<PagedData<ListComponent>> {
    return of(companyData.slice(offset, offset + limit)).pipe(
      delay(new Date(Date.now() + 500)),
      map(d => ({ data: d }))
    );
  }
  deleteVideo(param){
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.delete(this._baseUrl + 'deleteVideo', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  getWithSlug(text) {
    return this.httpClient.get(this._baseUrl + 'content?slug=' + text + '&status=active').pipe(
      map((response: any) => {
        // //(response, "response===============")
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getMakesList() {
    let params = new HttpParams().set('?search','').set('isDeleted','false').set('count', '2000');
    return this.httpClient.get(this._baseUrl + 'makes'+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getDetails(params) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.get(this._baseUrl + 'request'+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  downloadFile(url,context) {
    return this.httpClient.post<Blob>(this._baseUrl + url, context, { observe: 'response', responseType: 'blob' as 'json' })
  }
  downloadFileAll(url,context) {
    return this.httpClient.post<Blob>(this._baseUrl + url, context, { observe: 'response', responseType: 'blob' as 'json' })
  }
  add(url,context) {
    return this.httpClient.post(this._baseUrl + url, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  update(url,context) {
    return this.httpClient.put(this._baseUrl + url, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  updatePut(url,context, params) {
    return this.httpClient.put(this._baseUrl + url + params, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  updatePost(url,context, params) {
    return this.httpClient.post(this._baseUrl + url + params, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getAllContent(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'contents', { params: params   }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  acptRjct(url,context) {
    return this.httpClient.put(this._baseUrl + url, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getWithParms(url,params) {
    //console.log("thisssssssssss")
    return this.httpClient.get(this._baseUrl +url+params).pipe(
      map((response: any) => {
        return response;
      }),
      // catchError(this.handleError)
    )
  }
  getByAPIParms(url,param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl +url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  get(url,param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl +url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i];
        }
    }
    return null;
}

getDetail(Id) {
  // let params= this.getParams(param)
  return this.httpClient.get(this._baseUrl + 'prospecte?id='+ Id).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

  status(url,params) {   
     // let url = this._baseUrl + 'changeStatus?id=' + id + '&model=' + model + '&status=' + status;

    return this.httpClient.put(this._baseUrl+url+params, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  statusActive(url,params) {   
    // let url = this._baseUrl + 'changeStatus?id=' + id + '&model=' + model + '&status=' + status;

   return this.httpClient.put(this._baseUrl+url,params ).pipe(
     map((response: any) => {
       return response;
     }),
     catchError(this.handleError)
   )
 }
  statusChangeWithBody(url,params,data) {   
    // let url = this._baseUrl + 'changeStatus?id=' + id + '&model=' + model + '&status=' + status;
 
   return this.httpClient.put(this._baseUrl+url+params,data, {}).pipe(
     map((response: any) => {
       return response;
     }),
     catchError(this.handleError)
   )
 }
 moveBackRecord(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.delete(this._baseUrl + 'delete/undo', { params: params }).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}
 
deleteRecordPermanent(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.delete(this._baseUrl + 'permanent', { params: params }).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}



deleteRecords(url,context) {
  return this.httpClient.put(this._baseUrl + url, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}
  deleteRecord(url,params) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }

    return this.httpClient.delete(this._baseUrl + url+params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj
  }

  uploadOnlyImage(fileToUpload: File, type) {
    let params = "?modelName=" + type;
    const formData: FormData = new FormData();
    formData.append("data", fileToUpload, fileToUpload.name);
    formData.append("modelName", type);
    return this.httpClient
      .post(this._baseUrl + `uploadImages` + params, formData,{
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  uploadImage(fileToUpload: File,type) {
    // let params = '?modelName='+type
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    // formData.append('modelName',type);
    return this.httpClient.post(this._baseUrl + `upload/document`,formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );
  }
  uploadMultipleDocs(fileToUpload: File,) {
   
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
 
    return this.httpClient.post(this._baseUrl + `multiple/images`,formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );
  }
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error.code == 401) {
      return throwError('');
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    }
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // //console.log(errorMessage);
    return throwError(errorMessage);
  }
 
  uploadMultipleImage(params,formData) {
   
    return this.httpClient.post(this._baseUrl + `multiple/images`+params,formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );
  }
  deleteImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    // //console.log('params',param)
    
    return this.httpClient.delete(this._baseUrl + 'delete/document', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }


  deleteImagess(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    // //console.log('params',param)
    
    return this.httpClient.delete(this._baseUrl + 'deleteImage', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  // deleteImage
  deleteImages(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    // //console.log('params',param)
    
    return this.httpClient.delete(this._baseUrl + 'deleteImage', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  deleteDocument(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    // //console.log('params',param)
    
    return this.httpClient.delete(this._baseUrl + 'delete/document', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  ////////// Socket events///////////////////////////

  public sendMessage(message) {
    // //console.log("in new message",message)
    this.socket.emit("new-message", message);
  }
  public sendOne2OneMessage(message) {
    //console.log("one-to-one", message);
    
    this.socket.emit("one-to-one", message);
  };
  getMessages = (recipientId) => {
    //console.log(recipientId,'recipientI1111d11111111111111111111');
    
    return new Observable((observer) => {
      this.socket.on(`new-message${recipientId}`, (message) => {
        observer.next(message);
      });
    });
    // return this.socket.fromEvent('new-message').pipe(map( data => data )); 
    // return new Observable((observer) => {
    //   this.socket.on("new-message", (message) => {
    //     observer.next(message);
    //   });
    // });

    // return new Observable((observer) => {
    //   this.socket.on("new-message", (message) => {
    //     observer.next(message);
    //   });
    // });
   // this.socket.emit("fetch-history", data);

  };
  public disconnect = () => {
    return new Observable((observer) => {
      this.socket.on("disconnect", (message) => {
        observer.next(message);
      });
    });
  };
  public connect = () => {
    // return Observable.create((observer) => {
    //   this.socket.on("connect", (message) => {
    //     observer.next(message);
    //   });
    // });
    return new Observable((observer) => {
      this.socket.on("connection", (message) => {
        observer.next(message);
     });
    })
  };

  public addUser(newUser) {
    this.socket.emit("new-user", newUser);
  }

  public removeUser(newUser) {
    this.socket.emit("user-offline", newUser);
  }

  public showOnline(newUser) {
    this.socket.emit("user-online", newUser);
  }

  public sendMessageEvent(eventName, message) {
    this.socket.emit(eventName, message);
  }

  public fetch_history = (user) => {
    let params = new HttpParams();
    // let headers = new HttpHeaders();
    params.set("email", user.email);
    // return this.http.get(environment.config.CHAT_URL + '/admin/fetch_history?email=' + user.email)
    return this.httpClient.get(URL + "/admin/fetch_history?email=" + user.email);
  };

  public user_history = (to: string, from: string) => {
    // return this.http.get(environment.config.CHAT_URL + '/admin/user_history?from=' + from + '&to=' + to)
    return this.httpClient.get(URL + "/chat/user_history?from=" + from + "&to=" + to);
  };

  put(body, path) {
    let headers = new HttpHeaders();
    // let headers = this.getAuthorizationHeader();
    return this.httpClient.put(URL + path, body, {
      headers: headers,
    });
  }

  // get(path) {
  //   // const headers = this.getAuthorizationHeader();
  //   return this.httpClient.get(URL + path);
  // }

  public user_list = (id: string) => {
    let headers = new HttpHeaders();
    return this.httpClient.get("http://198.251.65.146:4705/chatUser?id=" + id, {
      headers: headers,
    });
  };


  handleError(error: HttpErrorResponse) {
    // //console.log(error);
    //console.log("thisssssssssss2")

    if (error.error.code == 401) {
      return throwError('');
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  public testMessages = () => {
    return Observable.create((observer:any) => {
      this.socket.on('new-message', (message:any) => {
        //console.log(message, '====== message recived');
        observer.next(message);

       
      });
    });

  };

  uploadImage1(fileToUpload: File,type) {
    let params = '?modelName='+type
    const formData: FormData = new FormData();
    formData.append('data', fileToUpload, fileToUpload.name);
    formData.append('modelName',type);
    return this.httpClient.post(this._baseUrl + `uploadImages`+params,formData);
  }

  public sendNotification(id?){
    //console.log('hiiiiiiiii');
  let data={
     "sendTo":id?id:"60bdbbf99c20735a69cfbbff"
  }
  //console.log(data);
  
    this.socket.emit("list-notificatons",data);
  
  }
  
  public getsendNotification = () => {
    return Observable.create((observer:any) => {
      this.socket.on('list-notificatons', (message:any) => {
        //console.log(message, '====== message recived');
        observer.next(message);

       
      });
  
  })
}


  
}
