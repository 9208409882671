import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})

export class dateFormat implements PipeTransform {
    transform(value): any {
        if(value!=null && value!=''){
            let date = value.year+'-'+value.month+'-'+value.day

            let newDate = new Date(date)
      let perfectDate = newDate.toLocaleString('UTC', {
        // weekday: 'short', // long, short, narrow
        day: 'numeric', // numeric, 2-digit
        month: '2-digit', // numeric, 2-digit, long, short, narrow
        year: 'numeric', // numeric, 2-digit
        
        // hour: 'numeric', // numeric, 2-digit
        // minute: 'numeric', // numeric, 2-digit
        // second: 'numeric', // numeric, 2-digit
    })
            
            return perfectDate;
        }
      
    }
}