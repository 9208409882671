import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StateData } from './common';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private state: StateData;


  constructor() { }
  
  set stateData(state) {
    this.state = state;
  }


  get stateData() {
    return this.state;
  }
  public setState(state: Partial<StateData>) {
    this.state = { ...this.state, ...state };
    // // //console.log('this.state',this.state)
  }

    /*   updateLists    */

    private updateLists = new Subject();
    public updateLists$ = this.updateLists.asObservable();
  
    public updateListsFun() {
      this.updateLists.next(true)
    }
    /* ****************** */

    /*   getContactData    */

    private getContactData = new Subject();
    public getContactData$ = this.getContactData.asObservable();
  
    public getContactDataFun() {
      this.getContactData.next(true)
    }
    /* ****************** */

    
     /*   patchValueConditionReport    */

     private patchValueConditionReport = new Subject();
     public patchValueConditionReport$ = this.patchValueConditionReport.asObservable();
   
     public patchValueConditionReportFun() {
       this.patchValueConditionReport.next(true)
     }
     /* ****************** */


}
