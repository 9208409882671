import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StripHtmlPipe} from './pipes/striphtml'
import {NumberDirective} from './directives/numbers-only.directive'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { removeDecimal } from './pipes/remove-decimal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxGalleryModule } from 'ngx-gallery';
import { AddclientComponent } from './addclient/addclient.component';
// import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEditTrimComponent } from './add-edit-trim/add-edit-trim.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AddContactPersonComponent } from './add-contact-person/add-contact-person.component';
import { dateFormat } from './pipes/date-format';
import { AddBankDetailsComponent } from './add-bank-details/add-bank-details.component';
import { BuyingDetailsComponent } from './buying-details/buying-details.component';
import { SharedTimelineComponent } from './shared-timeline/shared-timeline.component';
import { SharedAddClientComponent } from './shared-add-client/shared-add-client.component';
import { ConditionalReportComponent } from './conditional-report/conditional-report.component'
import { InputMaskModule } from 'primeng/inputmask';
import { EditorModule } from '@tinymce/tinymce-angular';
import { innerHtml } from './pipes/innerhtml';
import { EmialsComponent } from './emials/emials.component';
import { ReviewRequestComponent } from './review-request/review-request.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareOfferComponent } from './share-offer/share-offer.component';
@NgModule({
  declarations: [dateFormat,innerHtml,StripHtmlPipe,NumberDirective,removeDecimal,ConditionalReportComponent,
    BuyingDetailsComponent,AddclientComponent, AddEditTrimComponent, ReviewRequestComponent,
    AddContactPersonComponent, AddBankDetailsComponent, SharedTimelineComponent, SharedAddClientComponent, EmialsComponent, ShareOfferComponent],
  imports: [
    CommonModule,
    EditorModule,
    // CKEditorModule, 
    GooglePlaceModule,  NgMultiSelectDropDownModule.forRoot(), SelectDropDownModule,    NgxDaterangepickerMd.forRoot()
  ,AngularFileUploaderModule,NgxGalleryModule,FormsModule,
 

   ReactiveFormsModule,NgxSpinnerModule,
   InputMaskModule,
   AngularEditorModule,
   NgbModule
  ],
  exports:[
    dateFormat,innerHtml,EmialsComponent, StripHtmlPipe,removeDecimal,EditorModule,GooglePlaceModule,BuyingDetailsComponent,
    NumberDirective,NgMultiSelectDropDownModule, SelectDropDownModule,NgxDaterangepickerMd
,AngularFileUploaderModule,NgxSpinnerModule,FormsModule, ReactiveFormsModule,ReviewRequestComponent,
NgxGalleryModule,AddclientComponent,AddEditTrimComponent,AddContactPersonComponent,
AddBankDetailsComponent, SharedTimelineComponent ,  SharedAddClientComponent, ConditionalReportComponent,ShareOfferComponent
  ],
})
export class SharedModule { }
