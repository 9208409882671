import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorService } from '../../behavior.service';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-edit-trim',
  templateUrl: './add-edit-trim.component.html',
  styleUrls: ['./add-edit-trim.component.css']
})
export class AddEditTrimComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }

  @ViewChild('profileInput', { static: false }) profileInput;
  pageNo: any = ''
  progress: number = 0
  id: any = ''
  yearListData = [];
  featuresOption: FormArray;
  modelId: any;
  routerdata: any
  featuresForm: FormGroup
  imageLoader: boolean = false;
  fileToUpload: File = null;
  trimForm: FormGroup;
  closeResult = '';
  addModelSubmitted: boolean = false;
  trimEditData: any = ''
  public _host = environment.url;
  makeListData: any
  addSubmitted: boolean = false;
  modelsData: any;
  submitLoader: boolean = false
  addMakeModal: boolean = false;
  featureOptionData: any = []
  featureModal: boolean = false
  featureSubmitted: boolean = false
  featureName: any = ''
  featureOptionSendingData = []
  editAction: boolean = false
  makeForm: FormGroup;
  image: any = '';
  addModelDialog: boolean = false
  makeId: any = ''
  modelValue: any = ''

  config1 = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Make', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 100000, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Make', // label thats displayed in search input,
    searchOnKey: '',// key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys,
    enableSelectAll: false,
  }

  config2 = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Make', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 100000, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Make', // label thats displayed in search input,
    searchOnKey: 'name',// key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys,
    enableSelectAll: false,
  }
  currentURL: any
  dropdownSettings: any = []
  requestObservable: any;
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _Activate: ActivatedRoute,
    private _bs: BehaviorService,

  ) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      // selectAllText: 'Select All',
      enableCheckAll: false,
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
    };
    this.pageNo = this._Activate.snapshot.params['page'];
    this.id = this._Activate.snapshot.params['id'];
    let routerdata = this.router.url
    //console.log('routerdata', routerdata);
     this.getModals();
    this.getMakesData()
    this.createForm();
    this.getFeatureOptionFunc()
   
    this.commonService.trimAdded.subscribe(res => {
      if (res == false) {
        this.trimForm.reset();
        this.addSubmitted = false
      }
    })

    this.commonService.trimEdit.subscribe(elem => {
      //console.log(elem);

      if (elem.modelId != null && elem.modelId != undefined && elem.modelId != '' && elem.trimId != null && elem.trimId != undefined && elem.trimId != '') {
        this.editAction = true
        this.id = elem.trimId


        if (this.id) {
          this.getDetails();
          //console.log('this.trimEditData', this.trimEditData);

          setTimeout(() => {

            this.trimForm.patchValue({
              name: this.trimEditData['name'],
              gear: this.trimEditData['gear'],
              hp: this.trimEditData['hp'],
              metric: this.trimEditData['metric'],
              engineSize: this.trimEditData['engineSize'],
              torque: this.trimEditData['torque'],
              nm: this.trimEditData['nm'],
              pound: this.trimEditData['pound'],
              estimatedEmissionsTax: this.trimEditData['estimatedEmissionsTax'],
              factory_specification: this.trimEditData['factory_specification'],
              year: this.trimEditData['year'],
              kg: this.trimEditData['kg'],
              bodyStyle: this.trimEditData['bodyStyle'],
              driveTrain: this.trimEditData['driveTrain'],
              motorType: this.trimEditData['motorType'],
              modelId: this.trimEditData['modelId'] ? this.trimEditData['modelId'] : '',
              makeId: this.singleMake,
              amr_currency_code: this.trimEditData['amr_currency_code'] ? this.trimEditData['amr_currency_code'] : '',
              amr: this.trimEditData['amr'] ? this.trimEditData['amr'] : 0,
            })
            
            if (this.trimEditData.makeId != undefined && this.trimEditData.makeId != null && this.trimEditData.makeId != '') {
              this.getModelFunc(this.trimEditData['makeId'])
            }

            this.trimForm.controls.makeId.disable()
            this.trimForm.controls.modelId.disable()
          }, 1500)
        } else {
          this.trimEditData = ''
          this.trimForm.controls.makeId.enable()
          this.trimForm.controls.modelId.enable()
        }

      } else {
        this.editAction = false
      }
      this.modelId = elem.modelId

    })


    if (routerdata.match(`trim`)) {


      if (this.id) {
        this.getDetails();
        setTimeout(() => {

          this.trimForm.patchValue({
            name: this.trimEditData['name'],
            gear: this.trimEditData['gear'],
            hp: this.trimEditData['hp'],
            metric: this.trimEditData['metric'],
            engineSize: this.trimEditData['engineSize'],
            torque: this.trimEditData['torque'],
            nm: this.trimEditData['nm'],
            pound: this.trimEditData['pound'],
            estimatedEmissionsTax: this.trimEditData['estimatedEmissionsTax'],
            factory_specification: this.trimEditData['factory_specification'],
            year: this.trimEditData['year'],
            kg: this.trimEditData['kg'],
            bodyStyle: this.trimEditData['bodyStyle'],
            driveTrain: this.trimEditData['driveTrain'],
            motorType: this.trimEditData['motorType'],
            modelId: this.trimEditData['modelId'] ? this.trimEditData['modelId'] : '',
            makeId: this.trimEditData['makeId'] ? this.trimEditData['makeId'] : '',
            amr_currency_code: this.trimEditData['amr_currency_code'] ? this.trimEditData['amr_currency_code'] : '',
            amr: this.trimEditData['amr'] ? this.trimEditData['amr'] : 0,

          })
          if (this.trimEditData.makeId != undefined && this.trimEditData.makeId != null && this.trimEditData.makeId != '') {
            this.getModelFunc(this.trimEditData['makeId'])
          }

          // this.trimForm.controls.makeId.disable()
          // this.trimForm.controls.modelId.disable()
        }, 1000)
      } else {
        this.trimEditData = ''
        this.trimForm.controls.makeId.enable()
        this.trimForm.controls.modelId.enable()
      }
    }








  }

  ngOnInit() {
    this.routerdata = this.router.url.split('/')
    this.routerdata = this.routerdata[1]
    //console.log('routerdata', this.routerdata);

    this.getMakesData();
    this.generateArrayOfYears()
    this.makeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      image: [''],
    })
    this.featuresForm = this.formBuilder.group({
      featuresOption: this.formBuilder.array([this.creat()]),

    })
  }
  get fs() { return this.makeForm.controls }
  removeFeatureFunc(i) {
    this.featureOptionData.splice(i, 1)
  }

  onItemSelects(item: any) {
    // //console.log('item', item);

  }
  addFeature(): void {
    this.featuresOption = this.featuresForm.get('featuresOption') as FormArray;
    this.featuresOption.push(this.creat());
  }

  createFeature() {
    this.featuresForm = this.formBuilder.group({
      featuresOption: this.formBuilder.array([this.creat()]),

    })
  }
  creat():
    FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
    })
  }
  removeFeature(index: number) {
    this.featuresOption.removeAt(index);
  }


  onItemSelect(item: any) {
  }

  onSelectAlls(items: any) {

    if (items.length > 3) {
      this.toastr.error("please select 3 questions to continue")
    }
  }

  onSelectAll(items: any) {

  }


  getFeatureOptionFunc() {
    this.commonService.get('features/options').subscribe(res => {
      if (res.success) {
        let data = res.data
        this.featureOptionData = []
        data.filter(elem => {
          let addingData = {
            isChecked: false,
            id: elem.id,
            name: elem.name
          }
          this.featureOptionData.push(addingData)
        })
      } else {
        this.spinner.hide()
        this.toastr.error(res.error)
      }
    })
  }
  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 50
    for (var i = max; i >= min; i--) {
      this.yearListData.push(i)
    }
    return this.yearListData
  }
  addFeatureOption() {
    this.featureModal = true

  }


  removeOfferImage(image) {
    this.spinner.show();

    this.commonService.deleteImages({ "Imagename": image, "type": "makes" }).subscribe(res => {
      if (res.success) {
        this.image = '';

        this.toastr.success('Image Deleted Successfully!');
      } else {
        this.toastr.error(res.message, 'Error');
      }
      this.spinner.hide();
      return true;
    }, err => {
      this.spinner.hide();
      this.toastr.error(err.statusText, 'Error');
      return false;
    });
  }

  addFeatureSubmit() {

    this.featureSubmitted = true

    let item = this.featuresForm.get('featuresOption') as FormArray
    //console.log('this.prospectingForm.value.featuresOption', item);

    if (item.status == "INVALID") {
      return
    }
    let data = []
    this.featuresForm.value.featuresOption.map(itm => {
      if (itm != null) {
        if (itm.name == '') {
          this.toastr.error('Please fill the title')
          return
        }

        data.push(itm)
      }
    })

    //console.log('dta===>>>>', data);



    let val = {
      data: data
    }

    if (this.featureSubmitted) {
      let data = {
        name: this.featureName
      }
      this.spinner.show()
      this.commonService.add('features/features/multiple', val).subscribe(res => {
        if (res.success) {
          this.spinner.hide()
          let data = res.data

          data.filter(elem => {
            let addingData = {
              isChecked: false,
              id: elem.id,
              name: elem.name
            }
            this.featureOptionData.push(addingData)
          })
          // this.getFeatureOptionFunc()
          this.featureName = ''
          this.featureModal = false
          this.featureSubmitted = false
        } else {
          this.toastr.error(res.error)
          this.spinner.hide()
        }
      })
    }


  }
  calcMetric() {
    let hpValue = this.trimForm.value.hp
    this.trimForm.controls.metric.setValue(Math.round(hpValue * 1.0139))
  }
  calcHp() {
    let metricValue = this.trimForm.value.metric
    this.trimForm.controls.hp.setValue(Math.round(metricValue / 1.0139))
  }
  calcTorque() {
    let nmValue = this.trimForm.value.nm
    this.trimForm.controls.torque.setValue(Math.round(nmValue / 1.356))
  }
  calcNm() {
    let torqueValue = this.trimForm.value.torque
    this.trimForm.controls.nm.setValue(Math.round(torqueValue * 1.356))
  }
  addClientFunc() {

  }
  calckg() {
    let poundValue = this.trimForm.value.pound
    this.trimForm.controls.kg.setValue(Math.ceil(poundValue / 2.2046))
  }
  createForm() {
    this.trimForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      modelId: ['', [Validators.required]],
      makeId: ['', [Validators.required]],
      hp: [''],
      year: [''],
      metric: [''],
      engineSize: [''],
      electic_driving_miles: [0],
      electic_driving_km: [0],
      torque: [''],
      nm: [''],
      factory_specification: [''],
      pound: [''],
      kg: [''],
      featureOptions: [[]],
      bodyStyle: [''],
      driveTrain: [''],
      estimatedEmissionsTax: [''],
      gear: ['', [Validators.required]],
      motorType: ['', [Validators.required]],
      amr: [0],
      amr_currency_code: [''],
      purchase_price: [0],
      purchase_price_currency_code: [''],
      sale_price_clients: [0],
      sale_price_clients_currency_code: [''],
      sale_price_business_clients: [0],
      sale_price_business_clients_currency_code: [''],
      sale_price_dealers: [0],
      sale_price_dealers_currency_code: [],
    })
  }
  featureOptionCheckedFunc(event) {
    // //console.log('this.featureOptionSendingData blank', this.featureOptionSendingData)
    if (event.target.checked) {
      if (!this.featureOptionSendingData.includes(event.target.value)) {
        this.featureOptionSendingData.push({ 'checked': event.target.checked, 'id': event.target.value })
      }

    } else if (!event.target.checked) {
      let value = event.target.value;
      let ids = this.featureOptionSendingData.map(elem => {
        return elem.id
      })
      if (ids.includes(value)) {
        let indexValue = ids.indexOf(value)

        this.featureOptionSendingData.splice(indexValue, 1)
      }

    }
  }
  get f() { return this.trimForm.controls }

  Submit() {
    //console.log('dfgdfgdfgdggg', this.trimForm.controls);

    this.addSubmitted = true;
    if (!this.addSubmitted && this.trimForm.valid) {
      return
    }
    if (this.addSubmitted && !this.trimForm.invalid) {
      let datas = {
        name: this.trimForm.value.name ? this.trimForm.value.name:'',
        // modelId: this.trimForm.value.modelId ? this.trimForm.value.modelId.id : this.trimEditData.modelId.id,
        // makeId: this.trimForm.value.makeId ? this.trimForm.value.makeId.id : this.trimEditData.makeId,

        modelId: this.trimForm.value.modelId,
      makeId: this.trimForm.value.makeId,
        gear: this.trimForm.value.gear ? this.trimForm.value.gear : '',
        hp: this.trimForm.value.hp ? this.trimForm.value.hp : 0,
        electic_driving_miles: this.trimForm.value.electic_driving_miles ? this.trimForm.value.electic_driving_miles : 0,
        electic_driving_km: this.trimForm.value.electic_driving_km ? this.trimForm.value.electic_driving_km : 0,
        metric: this.trimForm.value.metric ? this.trimForm.value.metric : 0,
        engineSize: this.trimForm.value.engineSize ? this.trimForm.value.engineSize : '',
        estimatedEmissionsTax: this.trimForm.value.estimatedEmissionsTax ? this.trimForm.value.estimatedEmissionsTax : 0,
        year: this.trimForm.value.year ? this.trimForm.value.year : 0,
        factory_specification: this.trimForm.value.factory_specification ? this.trimForm.value.factory_specification :'',
        torque: this.trimForm.value.torque ? this.trimForm.value.torque:0,
        amr: this.trimForm.value.amr ? this.trimForm.value.amr :0,
        amr_currency_code: this.trimForm.value.amr_currency_code ? this.trimForm.value.amr_currency_code: '',

        pound: this.trimForm.value.pound ? this.trimForm.value.pound : 0,
        kg: this.trimForm.value.kg ? this.trimForm.value.kg : 0,
        bodyStyle: this.trimForm.value.bodyStyle ? this.trimForm.value.bodyStyle : '',
        nm: this.trimForm.value.nm ? this.trimForm.value.nm : 0,
        driveTrain: this.trimForm.value.driveTrain ? this.trimForm.value.driveTrain : '',
        motorType: this.trimForm.value.motorType ? this.trimForm.value.motorType : '',
        featureOptions: this.featureOptionSendingData,
        purchase_price: this.trimForm.value.purchase_price ? this.trimForm.value.purchase_price : 0,
        purchase_price_currency_code: this.trimForm.value.purchase_price_currency_code ? this.trimForm.value.purchase_price_currency_code : '',
        sale_price_clients: this.trimForm.value.sale_price_clients ? this.trimForm.value.sale_price_clients : 0,
        sale_price_clients_currency_code: this.trimForm.value.sale_price_clients_currency_code ? this.trimForm.value.sale_price_clients_currency_code : '',
        sale_price_business_clients: this.trimForm.value.sale_price_business_clients ? this.trimForm.value.sale_price_business_clients : 0,
        sale_price_business_clients_currency_code: this.trimForm.value.sale_price_business_clients_currency_code ? this.trimForm.value.sale_price_business_clients_currency_code : '',
        sale_price_dealers: this.trimForm.value.sale_price_dealers ? this.trimForm.value.sale_price_dealers : 0,
        sale_price_dealers_currency_code: this.trimForm.value.sale_price_dealers_currency_code ? this.trimForm.value.sale_price_dealers_currency_code : '',
      }
      this.submitLoader = true

      if (this.trimEditData == '') {
        this.commonService.add('trim', datas).subscribe(res => {

          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');
            this.modalService.dismissAll();
            this._bs.setTrim(res.success)
            this.trimForm.reset();
            this.submitLoader = false;
            this.addSubmitted = false;
            if (this.routerdata == 'trim') {
              this.router.navigateByUrl('/trim')
            } else {
              this.commonService.trimAdded.next(false)
            }
          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.submitLoader = false
        },
          error => {
            this.submitLoader = false
            // this.toastr.error(error, 'Error');
          });
      } else {

        let params = new HttpParams().set('?id', this.trimEditData.id)
        this.commonService.updatePut('trim', datas, params).subscribe(res => {

          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');


            this.trimForm.reset();
            this.submitLoader = false;
            this.addSubmitted = false;
            // this.router.navigateByUrl('/trim')
            //console.log('this.router.url', this.router.url);

            if (this.routerdata == 'trim') {
              this.router.navigateByUrl('/trim')
            } else {
              this.commonService.trimAdded.next(false)
              this.commonService.trimEdit.next({
                trimId: '',
                modelId: ''
              })
              this.editAction = false
            }



          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.submitLoader = false
        },
          error => {
            this.submitLoader = false
            // this.toastr.error(error, 'Error');
          });
      }

    }
  }


  getMakesData() {
    let data = new HttpParams().set('?search', '').set('isDeleted', 'false')
    this.commonService.getWithParms('makes', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.makeListData = [];
      } else {
        this.makeListData = response.data
        //console.log('this.makeListData', this.makeListData);

      }
    }, error => {
    });
  }
  getModelFunc(id) {


    let data = new HttpParams().set('?makeId', id).set('isDeleted', 'false')
    this.commonService.getWithParms('models', data).subscribe((response) => {
      if (response.success) {
        if (response.data.length == 0) {
          this.modelsData = [];
        } else {
          this.modelsData = response.data

        }
      }

    }, error => {
    });
  }
  makeChange(value) {
    //console.log('value',value)
    this.trimForm.patchValue({
      modelId: ''
    })
    // // //console.log('value', value.value.id);
    let id = value.value.id
    if (id != undefined) {
      let exist = this.getModal(id)
      this.makeId = id
      this.addModels(id, 'makeid')
      this.getModelFunc(id)
    }


  }
  closeModelFunc() {
    this.addModelSubmitted = false
    this.modelValue = ''
    this.addModelDialog = false
  }
  getModal(makeId) {
    // //console.log(makeId, 'ssssssssssssss');

    let exist = this.modelsData.filter(itm => itm.makeId == makeId)
    return exist
  }



  getModals() {
    this.commonService.get('models', { isDeleted: false }).subscribe(res => {
      if (res.success) {
        this.modelsData = res.data
      }
    },
      error => {
        // //console.log(error);

      }
    )
  }
  singleMake: any
  addModels(id, makeid?) {
    this.makeId = id
    if (!this.makeId) {
      this.toastr.error('Please select make first')
      return
    }
    if (makeid == 'makeid') {
      this.addModelDialog = false
    } else {
      this.addModelDialog = true
    }

  }
  getDetails() {
    if(this.id == true || this.id =="" || this.id ==undefined || this.id ==null){
      return
    }
    let params = new HttpParams().set('?id', this.id)
    this.commonService.getWithParms('trim', params).subscribe((res) => {
      if (res.success) {
        this.trimEditData = res.data
        //console.log('this.trimEditData', this.trimEditData);


        this.trimForm.patchValue({
          name: this.trimEditData['name'],
          gear: this.trimEditData['gear'],
          hp: this.trimEditData['hp'],
          metric: this.trimEditData['metric'],
          engineSize: this.trimEditData['engineSize'],
          torque: this.trimEditData['torque'],
          nm: this.trimEditData['nm'],
          pound: this.trimEditData['pound'],
          estimatedEmissionsTax: this.trimEditData['estimatedEmissionsTax'],
          factory_specification: this.trimEditData['factory_specification'],
          year: this.trimEditData['year'],
          kg: this.trimEditData['kg'],
          bodyStyle: this.trimEditData['bodyStyle'],
          driveTrain: this.trimEditData['driveTrain'],
          motorType: this.trimEditData['motorType'],
          // modelId: this.trimEditData['modelId'] ? this.trimEditData['modelId'] : '',
          // makeId: this.trimEditData['makeId'] ? this.trimEditData['makeId']: '',

          modelId: this.trimEditData['modelId'] ? this.trimEditData['modelId'].id : '',
            makeId: this.trimEditData['makeId'],

          purchase_price: this.trimEditData['purchase_price'] ? this.trimEditData['purchase_price'] : 0,
          purchase_price_currency_code: this.trimEditData['purchase_price_currency_code'] ? this.trimEditData['purchase_price_currency_code'] : '',
          sale_price_clients: this.trimEditData['sale_price_clients'] ? this.trimEditData['sale_price_clients'] : 0,
          sale_price_clients_currency_code: this.trimEditData['sale_price_clients_currency_code'] ? this.trimEditData['sale_price_clients_currency_code'] : '',
          sale_price_business_clients: this.trimEditData['sale_price_business_clients'] ? this.trimEditData['sale_price_business_clients'] : 0,
          sale_price_business_clients_currency_code: this.trimEditData['sale_price_business_clients_currency_code'] ? this.trimEditData['sale_price_business_clients_currency_code'] : '',
          sale_price_dealers: this.trimEditData['sale_price_dealers'] ? this.trimEditData['sale_price_dealers'] : 0,
          sale_price_dealers_currency_code: this.trimEditData['sale_price_dealers_currency_code'] ? this.trimEditData['sale_price_dealers_currency_code'] : '',
          electic_driving_miles: this.trimEditData['electic_driving_miles'] ? this.trimEditData['electic_driving_miles'] : '',
          electic_driving_km: this.trimEditData['electic_driving_km'] ? this.trimEditData['electic_driving_km'] : '',

        })
        // this.getModelFunc(this.trimEditData['makeId'])
        let trimId = this.trimEditData.makeId
        this.singleMake = this.makeListData.filter(function (item: any) {
          if (item.id == trimId) {
            return item

          }
        })
        this.featureOptionSendingData = this.trimEditData.featureOptions ? this.trimEditData.featureOptions : []
        let gettingFeatureData = this.featureOptionData.filter(o1 => this.featureOptionSendingData.some(o2 => o1.id === o2.id));

        if (this.featureOptionSendingData != undefined && this.featureOptionSendingData != null && this.featureOptionSendingData.length > 0) {

          for (let indexN = 0; indexN < this.featureOptionData.length; indexN++) {
            const elementN = this.featureOptionData[indexN];
            for (let index = 0; index < gettingFeatureData.length; index++) {
              const element = gettingFeatureData[index];
              if (elementN.id == element.id) {
                elementN.isSelected = true
              }

            }

          }
        }

      } else {
        this.toastr.error(res.error)
      }
    })
  }

  make(makes) {
    this.modalService.open(makes, { ariaLabelledBy: 'modal-basic-title', }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  makesubmit() {
    this.addSubmitted = true;
    if (!this.addSubmitted && this.makeForm.valid) {
      return
    }
    if (this.addSubmitted && !this.makeForm.invalid) {
      if (this.image == '' || this.image == null || this.image == undefined) {
        return
      } else {
        let data = {
          name: this.makeForm.value.name,
          image: this.image,
          model: 'makes'
        }
        this.commonService.add('make', data).subscribe(res => {
          let url;
          if (res.code == 200) {
            this.toastr.success(res.message, 'Success');
            this.getMakesData()
            this.addMakeModal = false;
            this.modalService.dismissAll();
            this.makeForm.reset();

          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          // this.spinner.hide();
        },
          error => {

          });

      }
    }
  }

  uploadOnlyImage(files: FileList) {
    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {

        this.fileToUpload = files.item(i)
      }
    }
    let type: 'users'
    if (this.fileToUpload) {

      this.imageLoader = true;
      this.commonService.uploadOnlyImage(this.fileToUpload, 'makes').subscribe((event: HttpEvent<any>) => {

        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            if (event.body.success) {
              this.image = event.body.data.imagePath;
              this.imageLoader = false;
              this.profileInput.nativeElement.value = '';
              this.imageLoader = false;
            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.imageLoader = false;
            }
            setTimeout(() => {
              this.progress = 0;
              this.imageLoader = false;
            }, 100);
        }
      }, err => {
        this.imageLoader = false;
      });
    }
  }
  kilometerCalc(value) {
    let kilometers = value * 1.609344;
    this.trimForm.controls.electic_driving_km.setValue(Math.round(kilometers))
  }
  milesCal(val) {
    let kilometers = val * 0.621371;
    this.trimForm.controls.electic_driving_miles.setValue(Math.round(kilometers))
  }


  modelSubmit() {
    this.addModelSubmitted = true;
    if (this.addModelSubmitted && this.modelValue !== '') {
      let data = {
        name: this.modelValue,
        makeId: this.makeId,
        model: 'models'
      }
      this.requestObservable = this.commonService.add('record', data).subscribe(res => {

        if (res.code == 200) {
          this.toastr.success(res.message, 'Success');

          this.getModelFunc(this.makeId)
          this.modelValue = '';
          this.addModelSubmitted = false;
          this.addModelDialog = false;

        } else {
          this.toastr.error(res.error.message, 'Error');
        }
      },
        error => {
        });



    }
  }
}
