import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-share-offer',
  templateUrl: './share-offer.component.html',
  styleUrls: ['./share-offer.component.css']
})
export class ShareOfferComponent implements OnInit {

  @Input() proId:any = '';
  @Input() singleData:any = '';
  @Input() viewalldata:any = '';
  @Output() result = new EventEmitter<any>();
  clientForm: FormGroup;
  cstmEmail: boolean = false;
  expenseListNorwegian: FormArray;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  selectedDatasource: any = ''
  config1 = {
    displayKey: 'custom', //if objects array passed which key to be displayed defaults to description
    value: this.selectedDatasource,
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Choose Email', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search email', // label thats displayed in search input,
    searchOnKey: '' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  userDatas: any[];
  totalItems: number = 0
  role: any = 'business_client';
  search:any='';
  isDeleted:any=false;
  page:any=1;
  _subscriberData: any;
  unregisteredUserSEmail: any = []

  TodayRate: any
  todayUSD: any
  todayKr: any
  todayEuro: any
  totalExpense: number = 0;

  paymentForm: FormGroup;
  publishedDeals: any = [];

  userData:any;
  addSubmitted:any
  constructor( private router: Router,private formBuilder: FormBuilder,
    private commonService: CommonService,

  ) { 
    this.userData = JSON.parse(localStorage.getItem("credentials"));
    this.paymentForm = this.formBuilder.group({
      vat_percentage: [0],
      vat_total: [0],
      sales_price: [0],
      expenses: this.formBuilder.array([]),
      expenses_total: [0],
      import_fee: [0],
      emission_tax: [0],
      sum_total: [0],
      estimated_market_price: [0],
      desired_sales_price: [0],
      estimated_profit_margin: [0],
      estimated_profit_margin_percentage: [0],
      emission_percentage: [0]
    })

    this.clientForm = this.formBuilder.group({
      businessClient: ["", [Validators.required]],
      description: [""],
      //Add apr 19 client requirement
      purchagePrice: ["", [Validators.required]],
      marginNumber: [false],
      margin: [0],
      marginInNum: [0],
      estimatedVat: [0, [Validators.required]],
      estimatedEmissionsTax: [0],
      expNorwegianTotal: [0],
      estimatedTotalCost: [0, [Validators.required]],
      estimatedDockFee: [0],
      expenseListNorwegian: this.formBuilder.array([this.createExpList()]),
      markup: [0],
      sum: [0],
      subline_message:['']

    });
  }


  get fc() {
    return this.clientForm.controls;
  }


  ngOnInit() {
    this.currencyConversion('USD');
    setTimeout(() => {
      this.currencyConversion('EUR');
    }, 1000);
    setTimeout(() => {
      this.currencyConversion('NOK');
    }, 1000);
  }

  createExpList(): FormGroup {
    return this.formBuilder.group({
      expTitle: [""],
      expCost: [""],
    });
  }

  expValue(item, ID, index) {

    let num: any = 0
    let value: any = this.paymentForm.value.expenses
    this.totalExpense = 0;
    value.map((res: any) => {
      if (ID == res.id || res.id == '1') {
        this.totalExpense = this.totalExpense + Number(res.amount)
        let array: any = this.publishedDeals[index]
        this.publishedDeals[index].estimation_details.expenses_total = this.totalExpense
        if (array.emission_tax == undefined) {
          array.emission_tax = 0
        } if (array.estimation_details.import_fee == undefined) {
          array.estimation_details.import_fee = 0
        } if (array.vat_total == undefined) {
          array.vat_total = 0
        }
        // //console.log('testttt', array.salesPriceNok.toFixed(0), array.vat_total.toFixed(0), array.estimation_details.import_fee, array.estimation_details.emission_tax, this.totalExpense);

        this.publishedDeals[index].estimation_details.sum_total = (Number(array.salesPriceNok.toFixed(0)) + Number(array.vat_total.toFixed(0)) + Number(array.estimation_details.import_fee) + Number(array.estimation_details.emission_tax) + Number(this.totalExpense)).toFixed(0)

      }
    })
    return this.totalExpense
  }

  currentRate(value) {
    if (value == 'USD') {
      return this.todayUSD
    } else if (value == 'NOK') {
      return this.todayKr
    } else if (value == 'EUR') {
      return this.todayEuro
    }

  }

  currencyConversion(value) {
    let vat_total
    let val: any
    let data = {
      from: 'USD',
      amount: 1,
      to: value
    }
    this.commonService.get('rate/allRateConversion', data).subscribe(res => {

      if (res.success) {
        this.TodayRate = res.data
        // //console.log(this.TodayRate, 'this.TodayRate')
        if (value == 'USD') {
          this.todayUSD = this.TodayRate
        } else if (value == 'NOK') {
          this.todayKr = this.TodayRate
          // //console.log(this.todayKr, 'this.todayKr,Nok')

        } else if (value == 'EUR') {
          this.todayEuro = this.TodayRate
        }



        return vat_total
      }
    })

  }

  ViewMore(ID) {
    let route = '/marketplace/view/' + ID;
    this.router.navigate([route], { queryParams: { type: 'inventory-tab' } });
  }

  closeModal(){
    this.result.emit({event:'close'})
  }

  hoursDifference: number;
  daysDifference: number;
  monthsDifference: number;
  yearsDifference: number;
  remaningDays(item) {
    // //console.log(item, 'rdate')
    // //console.log(item.getDay(), 'days')
    let oldDate: any = new Date()
    // //console.log(oldDate, 'oldDate')
    oldDate = new Date(oldDate.setMonth(6))
    // //console.log(oldDate, 'oldDate setmonth 6')

    let tenYear: any = new Date()
    // //console.log(tenYear, 'tenYear')
    let val = tenYear.getFullYear()
    // //console.log(val, 'val')
    tenYear = new Date(tenYear.setYear(val - 10))
    // //console.log(tenYear, 'tenYear')
    let oneYear: any = new Date()
    // //console.log(oneYear, 'oneYear')
    let vals = oneYear.getFullYear()
    // //console.log(vals, 'vals')
    oneYear = new Date(oneYear.setYear(vals - 1))
    // //console.log(oneYear, 'oneYear')

    let today = new Date()

    let totalDays = Math.abs(item.getTime() - today.getTime());
    let days22 = Math.ceil(totalDays / (1000 * 60 * 60 * 24));
    // //console.log(days22, 'days22')

    const differenceMs = item.getTime() - today.getTime();
    // //console.log(differenceMs, 'differenceMs')
    // Convert milliseconds to hours
    this.hoursDifference = Math.floor(differenceMs / (1000 * 60 * 60));
    // //console.log(this.hoursDifference, 'hoursDifference')
    // Convert milliseconds to days
    this.daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    // //console.log(this.daysDifference, 'daysDifference')
    // Convert milliseconds to months (approximately)
    this.monthsDifference = Math.floor(this.daysDifference / 30.44); // Average days in a month
    // //console.log(this.monthsDifference, 'monthsDifference')
    // Convert milliseconds to years (approximately)
    this.yearsDifference = Math.floor(this.monthsDifference / 12); // Average months in a year

    // //console.log(this.yearsDifference, 'yearsDifference')


    if (oldDate > item && item > oneYear) {
      let today = new Date()
      let startDate = new Date(item);
      // //console.log(startDate, 'startDate')
      let endDate = new Date(oldDate);
      // //console.log(endDate, 'endDate')
      let totalDays = Math.abs((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)); // it returns negative value if start date < end date
      // //console.log(totalDays, 'totalDays')
      totalDays = Math.floor(totalDays);
      // //console.log(totalDays, 'totalDays')
      let day = '6m+'
      // //console.log(day, 'day')
      return day
    }
    if (tenYear > item && oneYear > item) {
      let today = new Date()
      let startDate = new Date(item);
      let endDate = new Date(oldDate);
      let totalDays = Math.abs((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)); // it returns negative value if start date < end date
      totalDays = Math.floor(totalDays);
      let day = '10y+'
      return day
    }

    if (tenYear < item && oneYear > item) {
      let today = new Date()
      let startDate = new Date(item);
      let endDate = new Date(oldDate);
      let totalDays = Math.abs((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)); // it returns negative value if start date < end date
      totalDays = Math.floor(totalDays);
      let day = '6m+'
      return day
    }


    if (oldDate < item && oneYear < item) {
      let startDate = new Date(item);


      let endDate = new Date(oldDate);
      let totalDays = Math.abs((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)); // it returns negative value if start date < end date
      totalDays = Math.floor(totalDays);
      let diff = endDate.valueOf() - startDate.valueOf();
      let diffInHours: any = diff / 1000 / 60 / 60;
      let hours = diffInHours.toFixed(0)
      let day = totalDays + ' Days'
      return day
    }

  }

  getClientEmailFunc() {
    this.userDatas = [];
    this.totalItems = 0
    let data: any
    let role = 'business_client'
    let apiUrl: any = 'users'

    if (this.role != 'shipping_line') {
      apiUrl = 'users'
      data = new HttpParams().set('?role', role).set('search', this.search).set('count', '2000')
        .set('isDeleted', JSON.stringify(this.isDeleted)).set('page', JSON.stringify(this.page)).set('applicationStatus', "accept").set('formSubmitted', "true").set('status', 'active')
    } else {
      apiUrl = 'user/shipping_lines'
      data = new HttpParams().set('?role', role).set('search', this.search)
        .set('isDeleted', JSON.stringify(this.isDeleted)).set('page', JSON.stringify(this.page)).set('status', 'active')
    }
    this._subscriberData = this.commonService.getWithParms(apiUrl, data).subscribe((response) => {
      if (response.success) {
        let data = response.data;
        if (data.length) {
          data.map((response: any) => {
            response['custom'] = response.fullName + ' || ' + response.email
            return
          })
        }
        this.userDatas = data
        let value = {
          custom: 'Others'
        }
        // this.userDatas.splice(0, 0, value)

      }

      else {

        this.totalItems = response.total
      }
    }, error => {
    });
  }

  priceAndNegotiationForm(item) {
    this.unregisteredUserSEmail = item
    // //console.log(item,'item')
    if(this.unregisteredUserSEmail!=0){
      this.clientForm.controls["businessClient"].clearValidators();
      this.clientForm.controls["businessClient"].updateValueAndValidity();
  
    }

  }

  ViewValue(item) {
    return item
  }

  calculatorFunc(value, index) {

    // //console.log('value', value)
    let array: any = this.publishedDeals[index]
    // //console.log(array.salesPriceNok, 'array.salesPriceNok', array.emission_tax, 'array.emission_tax', array.vat_total, 'array.vat_total', this.totalExpense, 'this.totalExpense')
    array.estimation_details.sum_total = (Number(array.salesPriceNok) + Number(array.vat_total) + Number(array.estimation_details.import_fee) + Number(value) + Number(this.ViewValue(this.publishedDeals[index].estimation_details.expenses_total))).toFixed(0)
    return Number(array).toFixed(0)

  }
  calculateImportFee(value, index) {
    // //console.log('value', value)
    let array: any = this.publishedDeals[index]
    // //console.log(array.salesPriceNok, 'array.salesPriceNok', array.emission_tax, 'array.emission_tax', array.vat_total, 'array.vat_total', this.totalExpense, 'this.totalExpense')
    array.estimation_details.sum_total = (Number(array.salesPriceNok) + Number(array.emission_tax) + Number(array.vat_total) + Number(value) + Number(this.ViewValue(this.publishedDeals[index].estimation_details.expenses_total))).toFixed(0)
    return Number(array).toFixed(0)
    // ViewValue(publishedDeals[i].estimation_details.expenses_total)
  }
  calculateProfitMargin(value, index) {
    let array: any = this.publishedDeals[index]
    let estValue = Number(value) - Number(array.estimation_details.sum_total)
    // if (estValue < 1) {
    //   array.estimation_details.estimated_profit_margin = 0
    // } else {
    //   array.estimation_details.estimated_profit_margin = (estValue ? Number(estValue) : 0).toFixed(0)
    // }
    // let pValue = (Number(array.estimation_details.estimated_profit_margin) / Number(value)) * 100
    // if (pValue < 1) {
    //   array.estimation_details.estimated_profit_margin_percentage = 1
    // } else {
    //   array.estimation_details.estimated_profit_margin_percentage = (pValue ? Number(pValue) : 0).toFixed(1)

    // }


    array.estimation_details.estimated_profit_margin = (estValue ? Number(estValue) : 0).toFixed(0)

    let pValue = (Number(array.estimation_details.estimated_profit_margin) / Number(value)) * 100

    array.estimation_details.estimated_profit_margin_percentage = 1

    array.estimation_details.estimated_profit_margin_percentage = (pValue ? Number(pValue) : 0).toFixed(0)



    return array
  }


  calculateDesired(value, index) {
    let array: any = this.publishedDeals[index]

    return array

  }

  purChangePriceValue:any=0
  markupValue:any=0
  totalSum:any=0
  totalExpenseNorwegianSum: any = 0;
  vatValue: any = 25;
  calculatorFun(event?) {
    let marginValue = 0;
    let percent: any = 0;
    this.purChangePriceValue = Number(
      this.clientForm.controls.purchagePrice.value
    );

    if (this.clientForm.controls.marginNumber.value == 'false') {

      this.markupValue = Number(this.clientForm.controls.markup.value);
    } else {
      this.markupValue = Number(this.clientForm.controls.marginInNum.value)
      marginValue = this.clientForm.controls.marginInNum.value;
      this.totalSum = Number(this.purChangePriceValue) + Number(this.markupValue)
    }
    if (this.clientForm.controls.markup.value <= 0 || this.clientForm.controls.markup.value == null) {
      this.clientForm.controls.margin.setValue(0);
    }
    if (this.markupValue != 0 && this.purChangePriceValue != 0) {

      if (this.clientForm.controls.marginNumber.value == 'false') {

        this.clientForm.controls.marginInNum.setValue(0);
        percent = (this.markupValue / 100) * this.purChangePriceValue;
        this.clientForm.controls.margin.setValue(percent.toFixed(0));


        marginValue = this.clientForm.controls.margin.value;

        this.totalSum = Number(this.purChangePriceValue) + Number(marginValue);
      } else {
        let marginDataValue = this.clientForm.controls.marginInNum.value;
        if (marginDataValue > 0) {
          let marginInNumGetValue =
            (marginDataValue * 100) / this.purChangePriceValue;
          this.markupValue = Number(marginInNumGetValue);

          this.clientForm.controls.markup.setValue(0);
          0
        }
        marginValue = Number(this.clientForm.controls.marginInNum.value);
        this.markupValue = Number(this.clientForm.controls.marginInNum.value)
        this.totalSum = Number(this.purChangePriceValue) + Number(marginValue);
      }
    } else if (this.totalSum == 0) {
      this.totalSum = Number(this.purChangePriceValue);
      this.clientForm.controls.margin.setValue(0);
      this.clientForm.controls.sum.setValue(this.totalSum.toFixed(0));
    }
    this.clientForm.controls.sum.setValue(this.totalSum.toFixed(0));

    if (this.userData.role == "business_partner") {
      this.totalExpenseNorwegianSum = 0;
      let totalExpenseNorwegianSumLocal = 0;
      if (this.expenseListNorwegian != undefined && this.expenseListNorwegian != null && this.expenseListNorwegian.length > 0) {
        this.expenseListNorwegian.value.forEach((x) => {
          totalExpenseNorwegianSumLocal += +x.expCost;
        });
      }

      this.totalExpenseNorwegianSum = totalExpenseNorwegianSumLocal;

      this.clientForm.controls.expNorwegianTotal.setValue(
        this.totalExpenseNorwegianSum.toFixed(0)
      );
    }

    setTimeout(() => {
      this.clientForm.controls.sum.setValue(this.totalSum.toFixed(0));

      let sumValue = this.clientForm.controls.sum.value;
      let estimateVatValue = (this.vatValue / 100) * sumValue;
      this.clientForm.controls.estimatedVat.setValue(
        estimateVatValue.toFixed(0)
      );
      let estimatedVatValue = Number(
        this.clientForm.controls.estimatedVat.value
      );
      let estimatedDockFeeValue = Number(
        this.clientForm.controls.estimatedDockFee.value
      );
      let expNorwegianTotalValue = Number(
        this.clientForm.controls.expNorwegianTotal.value
      );

      this.clientForm.controls.estimatedTotalCost.setValue(
        (
          Number(this.clientForm.controls.estimatedEmissionsTax.value) +
          +sumValue +
          +estimatedDockFeeValue +
          +estimatedVatValue +
          +expNorwegianTotalValue
        ).toFixed(0)
      );


    }, 200);
  }

}
